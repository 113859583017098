import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import i18n from 'i18next';

import {SUPPORTED_LANGUAGES} from "./utils";

i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: SUPPORTED_LANGUAGES,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['cookie'],
            caches: ['cookie'],
        },
    });

export default i18n;