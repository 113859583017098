import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import CountDown from "../index";
import "./style.scss"

const CountDownWithContainer = ({date, day, text, loading, success, isReloadPage = false, callBackReload}) => {
    const timeBlockStyle = classNames("countdown-container_time", {skeleton: loading})
    const {t} = useTranslation()

    return (
        <div className="countdown-container">
            <p className="countdown-container_text">{text}</p>
            <div className={timeBlockStyle}>
                {!success && !loading && <h2>{t("comingSoon")}</h2>}
                {success &&
                    <CountDown
                        day={day}
                        date={date}
                        isReloadPage={isReloadPage}
                        callBackReload={callBackReload}
                    />}
            </div>
        </div>
    )
}

export default memo(CountDownWithContainer)