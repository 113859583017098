export const checkAndFixNumber = (num,fixedNumber) => {
    const strNum = num.toString();

    if (strNum.includes(".")) {
        const [integer, decimal] = strNum.split(".");

        if (decimal.length < 3) {
            return num;
        }

        return parseFloat(num.toFixed(fixedNumber));
    }

    return num;
}