import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ethers} from "ethers";

import {ERC20, UniswapV2Pool, UniswapV3Pool} from "web3/ABI";
import {getEthPrice} from "store/Global/globalSlice";
import {CHAIN_ID} from "utils";

import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import {Button, Image} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const Token = () => {
    const {ethPrice} = useSelector(state => state.global)
    const {currentChain} = useSelector(state => state.web3)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [oneKompeteWETHPrice, setOneKompeteWETHPrice] = useState(0)

    useEffect(() => {
        dispatch(getEthPrice())
        getKompetePrice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChain]);

    const getTokenContractsAndDecimals = async (chain, poolContractType) => {
        const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
        const poolAddress = chain.contractAddress.pool;

        const poolContract = new ethers.Contract(poolAddress, poolContractType, provider);

        const token0Address = await poolContract.token0();
        const token1Address = await poolContract.token1();

        const token0Contract = new ethers.Contract(token0Address, ERC20, provider);
        const token1Contract = new ethers.Contract(token1Address, ERC20, provider);

        const decimals0 = await token0Contract.decimals();
        const decimals1 = await token1Contract.decimals();

        return {token0Contract, token1Contract, poolContract, decimals0, decimals1};
    }

    const kompetePriceCalculationEthChain = async (chain) => {
        const {poolContract, decimals0, decimals1} = await getTokenContractsAndDecimals(chain, UniswapV2Pool);

        const reserves = await poolContract.getReserves();

        const reserve0 = ethers.utils.formatUnits(reserves[0], decimals0);
        const reserve1 = ethers.utils.formatUnits(reserves[1], decimals1);

        const token0PriceV2 = reserve1 / reserve0;

        setOneKompeteWETHPrice(token0PriceV2);
    };

    const kompetePriceCalculationBaseChain = async (chain) => {
        const {poolContract, decimals0, decimals1} = await getTokenContractsAndDecimals(chain, UniswapV3Pool);

        const [sqrtPriceX96] = await poolContract.slot0();

        const buyOneOfToken0 = Math.pow(sqrtPriceX96 / Math.pow(2, 96), 2) / (Math.pow(10, decimals1) / Math.pow(10, decimals0)).toFixed(decimals1);
        const buyOneOfToken1 = (1 / buyOneOfToken0).toFixed(decimals0);

        setOneKompeteWETHPrice(buyOneOfToken1);
    };

    const getKompetePrice = async () => {
        const {chainId} = currentChain

        setOneKompeteWETHPrice(0)

        switch (chainId) {
            case CHAIN_ID.ETH_MAINNET:
                await kompetePriceCalculationEthChain(currentChain)
                break
            case CHAIN_ID.BASE_MAINNET:
                await kompetePriceCalculationBaseChain(currentChain)
                break
            default:
                setOneKompeteWETHPrice(0.0)
        }
    }

    const uniswapLink = `https://app.uniswap.org/swap?outputCurrency=${currentChain.contractAddress.payment}&chain=${currentChain.network.toLowerCase()}`

    return (
        <section className="bazaar-token">
            <CosmeticViewTemplate
                classname="bazaar-token_content container"
                title={t("tokenTabTitle")}
                img={png.KompeteCoinBig}
                rarity="divine"
                attribute={[{name: "virtual currency"}]}
            >
                <div className="bazaar-token_content_current-price">
                    <span>{t("currentPrice")}</span>
                    <div className="bazaar-token_content_current-price_info">
                        <Image alt="kompete" src={png.KompeteCoin}/>
                        {<span className={oneKompeteWETHPrice ? "" : "skeleton"}>
                            {!!oneKompeteWETHPrice ? `$${(oneKompeteWETHPrice * ethPrice).toFixed(4)}` : ""}
                        </span>}
                    </div>
                </div>
                <div className="bazaar-token_content_btns">
                    <Button onClick={() => window.open(uniswapLink)}>{t("tokenTabUniswapButtonText")}</Button>
                    <Button onClick={() => navigate('/bazaar?tab=token')} view="outline">{t("tokenTabInstructionsButtonText")}</Button>
                </div>
                <div className="bazaar-token_content_description">
                    <span>{t("description")}</span>
                    <p>{t("tokenTabDescription")}</p>
                </div>
            </CosmeticViewTemplate>
        </section>
    )
}

export default Token