import React from "react";
import {useTranslation} from "react-i18next";

const LimitedTimeShopClose = () => {
    const {t} = useTranslation()

    return (
        <div className="shop-limited_close-container">
            <p>{t("limitedClosedText")}</p>
        </div>
    )
}

export default LimitedTimeShopClose;