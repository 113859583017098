import crypto from "crypto-browserify"

export const encryptData = (data) => {
    const cryptoSecretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
    const cryptoIv = process.env.REACT_APP_CRYPTO_IV

    const savedSecretKey = Buffer.from(cryptoSecretKey, "hex")
    const savedIv = Buffer.from(cryptoIv, "hex")
    const jsonString = JSON.stringify(data)

    const cipher = crypto.createCipheriv("aes-256-cbc", savedSecretKey, savedIv);

    let encrypted = cipher.update(jsonString, "utf-8", "hex");
    encrypted += cipher.final("hex");

    return encrypted
}