import React from "react";
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const EconomyBuySell = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <section className="economy_buy-sell container">
            <Title underline={true}><span>{t("gameEconomyBuyTitlePartOne")}</span> {t("gameEconomyBuyTitlePartTwo")}</Title>
            <div className="economy_buy-sell_content">
                <div className="economy_buy-sell_content_img-block">
                    <ProgressiveImage
                        alt="token"
                        imgSrc={png.KompeteTokenStackedPng}
                        previewSrc={png.KompeteTokenStackedWebp}
                    />
                </div>
                <div className="economy_buy-sell_content_info">
                    <h3>{t("gameEconomyBuySubTitle")}</h3>
                    <p>{t("gameEconomyBuyDescription")}</p>
                    <Button
                        view="secondary"
                        onClick={() => navigate("/bazaar?tab=items ")}
                    >
                        {t("gameEconomyBuyButtonText")}
                    </Button>
                </div>
            </div>
        </section>
    )
}
export default EconomyBuySell