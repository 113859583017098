import {png} from "assets/png"

export const voolahPackageDetails = [
    {
        id: 0,
        src: {big: png.Voolah500, small: png.Voolah500Small},
        alt: "voolah-500",
        value: 500,
        price: 4.97,
        text: "voolah500Description"
    },
    {
        id: 1,
        src: {big: png.Voolah1000, small: png.Voolah1000Small},
        alt: "voolah-1000",
        value: 1000,
        price: 8.97,
        text: "voolah1000Description"
    },
    {
        id: 2,
        src: {big: png.Voolah2800, small: png.Voolah2800Small},
        alt: "voolah-2800",
        value: 2800,
        price: 23.97,
        receive: 12,
        text: "voolah2800Description"
    },
    {
        id: 3,
        src: {big: png.Voolah5000, small: png.Voolah5000Small},
        alt: "voolah-5000",
        value: 5000,
        price: 37.97,
        receive: 25,
        text: "voolah5000Description"
    },
    {
        id: 4,
        src: {big: png.Voolah13500, small: png.Voolah13500Small},
        alt: "voolah-13500",
        value: 13500,
        price: 88.97,
        receive: 35,
        text: "voolah13500Description"
    }
]

export const shopTabOptions = [
    {id: "limited", name: "Limited Time", icon: png.LimitedTimeTab},
    {id: "divine", name: "Divine", icon: png.DivineTab},
    {id: "voolah", name: "Voolah", icon: png.VoolahCoin},
]

export const LIMITED_SLOTS_DEFAULT_IMG = {
    "1": png.MysteryCosmetic4,
    "2": png.MysteryCosmetic5,
    "3": png.MysteryCosmetic6,
    "4": png.MysteryCosmetic2,
    "5": png.MysteryCosmetic1,
    "6": png.MysteryCosmetic3
}

export const LG_SLOTS = ["1", "2"]

export const MD_SLOTS = ["3", "4", "5", "6"]

export const PAYMENT_TYPE = {
    ETH: "ETH",
    KOMPETE: "KOMPETE"
}