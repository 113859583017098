import React from "react";
import {useNavigate} from "react-router-dom";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import CosmeticSuccessModalTemplate from "components/molecules/CosmeticModalTemplate";
import {useTranslation} from "react-i18next";

const FreeItemClaimSuccessModal = ({show}) => {
    const {currentModal, setCurrentModal} = useModalsContext()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {inventory} = currentModal.data

    return (
        <CosmeticSuccessModalTemplate
            show={show}
            quantity={1}
            isShowClose={true}
            name={inventory.name}
            rarity={inventory.rarity}
            image={inventory.image_url}
            title={t("claimSuccess")}
            closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
            buttonOne={{text: t("downloadGame"), callBack: () => navigate("/download")}}
            buttonTwo={{text: t("goToLocker"), callBack: () => navigate("/locker")}}
        />
    )
}

export default FreeItemClaimSuccessModal