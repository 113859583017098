import React from "react";
import {useTranslation} from "react-i18next";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import {Button} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const TokenWhitePaper = () => {
    const {t} = useTranslation()

    return (
        <section className="token-white-paper">
            <div className="container">
                <Title underline={true}>{t("tokenTabWhitepaperTitlePartOne")} <br/><span>{t("tokenTabWhitepaperTitlePartTwo")}</span></Title>
                <div className="token-white-paper_content">
                    <div>
                        <div>
                            <p>{t("tokenTabWhitepaperContent")}</p>
                            <Button
                                onClick={() => window.open("https://modernize-games.gitbook.io/kompete-token-whitepaper/", "_blank")}
                            >
                                {t("tokenTabWhitepaperButtonText")}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <ProgressiveImage
                            alt="token"
                            imgSrc={png.KompeteTokenBookPng}
                            previewSrc={png.KompeteTokeBookWebp}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenWhitePaper