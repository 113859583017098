import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";

import {
    wait,
    ERROR,
    STEPS_STATUS,
    PAYMENT_TYPE,
    errorHandler,
    GLOBAL_MODALS,
    checkAndFixNumber,
    formatNumberToEuropeanStyle,
    walletAndTransactionErrorHandle
} from "utils";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {useConnectedWallet, useConnectWallet} from "hooks";
import useDivineShop from "../useDivineShop";

import PaymentMethod from "components/molecules/PaymentMethodTemplate";
import {CosmeticModalTemplate} from "components/molecules";

import "../style.scss"

const DivineOrderModal = () => {
    const navigate = useNavigate()

    const {disconnectWallet, openWalletModal} = useConnectWallet()
    const {
        address,
        providers,
        isConnected,
        getEthBalance,
        getKompeteBalance,
        isConnectedAddressCorrect,
        isConnectedChainCorrect
    } = useConnectedWallet()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()

    const {currentChain} = useSelector(state => state.web3)
    const {ethBalance, wallet, kompeteBalance} = useSelector(state => state.authV2.signIn.userData)

    const {inventory, quantity, payment} = currentModal.data

    const {walletConnectSigner} = providers()

    useEffect(() => {
        if (!ethBalance) getEthBalance(address)
        if (!kompeteBalance) getKompeteBalance(address)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const detectAndCalculateInventoryPrice = () => {
        switch (payment) {
            case PAYMENT_TYPE.ETH:
                const currentInventoryEthPrice = checkAndFixNumber(quantity * inventory.eth_price, 3)
                const formattedEthBalance = ethBalance ? Number(formatNumberToEuropeanStyle(ethBalance)).toFixed(5) : 0
                const isEthBalanceCorrect = Number(ethBalance) >= currentInventoryEthPrice

                return {
                    inventoryPrice: currentInventoryEthPrice,
                    isBalanceCorrect: isEthBalanceCorrect,
                    balance: formattedEthBalance,
                    payment: PAYMENT_TYPE.ETH
                }
            case PAYMENT_TYPE.KOMPETE:
                const currentInventoryKompetePrice = checkAndFixNumber(quantity * inventory.kompete_price, 3)
                const formattedKompeteBalance = kompeteBalance ? Number(formatNumberToEuropeanStyle(checkAndFixNumber(kompeteBalance))) : 0
                const isKompeteBalanceCorrect = Number(kompeteBalance) >= currentInventoryKompetePrice

                return {
                    inventoryPrice: currentInventoryKompetePrice,
                    isBalanceCorrect: isKompeteBalanceCorrect,
                    balance: formattedKompeteBalance,
                    payment: PAYMENT_TYPE.KOMPETE
                }
        }
    }

    const isBalanceCorrect = detectAndCalculateInventoryPrice().isBalanceCorrect
    const inventoryPrice = detectAndCalculateInventoryPrice().inventoryPrice
    const paymentType = detectAndCalculateInventoryPrice().payment
    const methodKey = payment.toLowerCase()
    const balance = detectAndCalculateInventoryPrice().balance
    const {t} = useTranslation()

    const {createSellOrder, createBuyOrder, sendTransaction} = useDivineShop({
        paymentType: payment,
        chainId: currentChain.chainId,
        price: inventoryPrice.toString(),
        signer: walletConnectSigner,
        quantity,
        tokenId: inventory.token_id,
        account: wallet
    })

    const mintInventory = async () => {
        if (!isConnectedChainCorrect) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.INVALID_CHAIN)
            resetStatesAndClose()
            disconnectWallet()
            return
        }

        if (!isConnectedAddressCorrect) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)
            resetStatesAndClose()
            disconnectWallet()
            return
        }

        setModal(GLOBAL_MODALS.PROCESS)
        setCurrentModal({status: STEPS_STATUS.CLOSE})

        try {
            const {sellOrder, sellSignature} = await createSellOrder()

            const {buyOrder, buySignature} = await createBuyOrder()

            await wait(4000)

            try {
                const result = await sendTransaction(sellOrder, sellSignature, buyOrder, buySignature)
                if (!result.status)
                    errorHandler(ERROR.TRANSACTION_FAILED)

                setCurrentModal({status: STEPS_STATUS.SUCCESS, data: currentModal.data})
                setModal(GLOBAL_MODALS.CLOSE)
                getEthBalance(address)
            } catch (error) {
                const {message} = error
                let errorMessage = message.length > 100 ? walletAndTransactionErrorHandle(error, false) : message
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(errorMessage)
                resetStatesAndClose()
            }
        } catch (error) {
            setMessage(error?.message ?? ERROR.WRONG)
            setModal(GLOBAL_MODALS.ERROR)
            resetStatesAndClose()
        }
    }

    const getTextByMethodKey = (ethTextKey, kompeteTextKey) => methodKey === "eth" ? t(ethTextKey) : t(kompeteTextKey);

    const payText = getTextByMethodKey("payEthButtonText", "payKompeteButtonText");
    const buyText = getTextByMethodKey("buyEth", "buyKompete");
    const insufficientBalanceText = getTextByMethodKey("limitedInventoryOrderModalEthInsufficient", "insufficientKompeteText");

    const methodDetails = {
        [methodKey]: {
            text: isConnected ? isBalanceCorrect ? payText : buyText : t("connectWalletButtonText"),
            onClick: () =>
                isConnected
                    ? isBalanceCorrect
                        ? mintInventory()
                        : navigate("/bazaar?tab=token", {state: {process: "order-more-eth"}})
                    : (setCurrentModal({status: STEPS_STATUS.CLOSE}), openWalletModal()),
            error: isConnected ? isBalanceCorrect ? "" : insufficientBalanceText : "",
            totalPrice: `${inventoryPrice}  ${paymentType}`
        }
    }

    return (
        <CosmeticModalTemplate
            show={true}
            rarity="divine"
            quantity={quantity}
            title={t("limitedInventoryOrderModalTitle")}
            name={inventory.name}
            image={inventory.image_url}
            buttonOne={{
                callBack: () => methodDetails[methodKey].onClick(),
                text: methodDetails[methodKey].text
            }}
            isShowClose={true}
            closeClick={() => setCurrentModal({status: ""})}
        >
            <div className="shop-divine_order-summery_content_payments-methods">
                <div className="shop-divine_order-summery_content_payments-methods_head">
                    <div
                        className="shop-divine_order-summery_content_payments-methods_head_quantity">
                        <span>{t("limitedInventoryOrderModalPaymentMethod")}</span> <span>(1)</span>
                    </div>
                    <span className="shop-divine_order-summery_content_payments-methods_head_error">
                        {methodDetails[methodKey].error}
                    </span>
                </div>
                <PaymentMethod
                    checked={true}
                    disable={true}
                    method={methodKey}
                    balance={balance}
                    insufficient={!!methodDetails[methodKey].error}
                />
            </div>
            <div className="shop-divine_order-summery_content_total">
                <div>{t("total")}</div>
                <div>{methodDetails[methodKey].totalPrice}</div>
            </div>
            {methodDetails[methodKey].error &&
                <div className="shop-divine_order-summery_content_payments-methods_bottom_error">
                    <p>{methodDetails[methodKey].error}</p>
                </div>
            }
        </CosmeticModalTemplate>
    )
}

export default DivineOrderModal