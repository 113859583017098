import React from "react";
import {Navigate} from "react-router-dom";

import {BANNER} from "../utils";

import BazaarCurrentAlreadyListedWeb3Cosmetic from "../pages/Bazaar/BazaarCurrentCosmetic/BazaarCurrentAlreadyListedWeb3Cosmetic";
import {LockerCurrentNotListedWeb3Cosmetic, LockerCurrentWeb2Cosmetic} from "../pages/Locker/LockerCurrentCosmetic";
import TermsConditions from "../pages/TermsConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import NewsPost from "../pages/News/NewsPosts";
import Success from "../pages/LP/Success";
import FreeItem from "../pages/FreeItem";
import Download from "../pages/Download";
import Option from "../pages/LP/Option";
import PageNotFound from "../pages/404";
import Support from "../pages/Support";
import Profile from "../pages/Profile";
import Locker from "../pages/Locker";
import Bazaar from "../pages/Bazaar";
import Delete from "../pages/Delete";
import Links from "../pages/Links";
import Home from "../pages/Home";
import Shop from "../pages/Shop";
import News from "../pages/News";
import Deck from "../pages/Deck";


export const routes = [
    {
        path: '/',
        element: <Home/>
    },
    {
        path: '/shop',
        element: <Shop/>
    },
    {
        path: '/news',
        element: <News/>
    },
    {
        path: '/links',
        element: <Links/>
    },
    {
        path: '/pitch',
        element: <Deck/>
    },
    {
        path: '/bazaar',
        element: <Bazaar/>
    },
    {
        path: '/locker',
        element: <Locker/>
    },
    {
        path: '/delete',
        element: <Delete/>
    },
    {
        path: '/support',
        element: <Support/>
    },
    {
        path: '/lp/pc-02',
        element: <Option/>
    },
    {
        path: '/profile',
        element: <Profile/>
    },
    {
        path: '/news/:id',
        element: <NewsPost/>
    },
    {
        path: '/lp/pc-01',
        element: <Download/>
    },
    {
        path: '/download',
        element: <Download/>
    },
    {
        path: '/free/item',
        element: <FreeItem/>
    },
    {
        path: '/lp/mobile-02',
        element: <Option/>
    },
    {
        path: '/lp/mobile-01',
        element: <Download/>
    },
    {
        path: '/terms',
        element: <TermsConditions/>
    },
    {
        path: '/privacy',
        element: <PrivacyPolicy/>
    },
    {
        path: '/lp/pc-02-success',
        element: <Success/>
    },
    {
        path: '/lp/mobile-02-success',
        element: <Success/>
    },
    {
        path: '/download',
        element: <Navigate to="/" replace/>
    },
    {
        path: '/token',
        element: <Navigate to="/en/bazaar?tab=token" replace/>
    },
    {
        path: '/lp/download',
        element: <Download banner={BANNER.DISCORD}/>
    },
    {
        path: '/locker/inventory/:sku',
        element: <LockerCurrentWeb2Cosmetic/>
    },
    {
        path: '/locker/inventory/:sku/:token_id',
        element: <LockerCurrentNotListedWeb3Cosmetic/>
    },
    {
        path: '/bazaar/inventory/:sku/:token_id/:order_id',
        element: <BazaarCurrentAlreadyListedWeb3Cosmetic/>
    },
    {
        path: '*',
        element: <PageNotFound/>
    }
]