import React, {useEffect, memo} from "react"
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {globalService} from "store/Global/globalService";
import {COUNTRY_TO_LANGUAGE_MAP} from "utils";

import Wrapper from "../PageWrapper";
import Header from "./Header";
import Footer from "./Footer";

const DefaultLayout = (
    {
        banner,
        children,
        menu = true,
        isFollowUsBack,
        isFooter = true,
        onlyLogo = false,
        isFollowUsShow = true
    }) => {

    const location = useLocation()
    const {} = useTranslation()

    useEffect(() => {
        window.scroll(0, 0)
        window.history.scrollRestoration = 'manual'

        if (sessionStorage.getItem("_paq") !== location.pathname) {
            sessionStorage.setItem("_paq", "")
            setTimeout(() => {
                const {_paq} = window
                _paq.push(['setCustomUrl', window.location.href]);
                _paq.push(['setDocumentTitle', document.title]);
                _paq.push(['trackPageView']);
            }, 0)
        }
        checkingCurrentLanguage()
    }, [location.pathname])

    const checkingCurrentLanguage = () => {
        const isLangAvailable = localStorage.getItem("i18next");

        if (!isLangAvailable) {
            globalService.getCurrentIpData().then(({country_code}) => {
                const countryLang = COUNTRY_TO_LANGUAGE_MAP[country_code]
                if (countryLang) {
                    i18next.changeLanguage(countryLang)
                    localStorage.setItem("i18next", countryLang)
                } else {
                    localStorage.setItem("i18next", "en")
                    i18next.changeLanguage("en")
                }
            })
        }
    }

    return (
        <Wrapper>
            {menu && <Header banner={banner} onlyLogo={onlyLogo}/>}
            <main>{children}</main>
            {isFooter && <Footer isFollowUsShow={isFollowUsShow} isFollowUsBack={isFollowUsBack}/>}
        </Wrapper>
    )
}

export default memo(DefaultLayout)