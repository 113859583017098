import {CHAIN_ID} from "./enum";

export const LIMIT_DEFAULT_DATA = {value: 12, name: "12"}

export const STANDARD_LIMIT_OPTIONS = [
    {name: "12", value: 12},
    {name: "24", value: 24},
    {name: "36", value: 36}
]

export const TABLE_HEADER = [
    {displayName: "Name", value: "name", className: "table-name"},
    {displayName: "Transaction Id", value: "transactionHash"},
    {displayName: "Transaction Type", value: "process", className: "table-type"},
    {displayName: "Time", value: "blockTimestamp", className: "table-time"},
    {displayName: "Qty", value: "value", className: "table-type"},
    {displayName: "Total Amount", value: "price", className: "table-price"},
]

export const TRANSACTION_HISTORY_SECTION = {
    USER_ACTIVITY: "user-activity",
    INVENTORY_ACTIVITY: "inventory-activity",
    BAZAAR_ACTIVITY: "bazaar-activity"
}

export const SORT_OPTIONS = [
    {name: "Price High To Low", value: "DESC"},
    {name: "Price Low To High", value: "ASC"},
]

export const SORT_OPTIONS_V2 = [
    ...SORT_OPTIONS,
    {name: "Recently Listed", value: "LAST_LISTED"},
    {name: "Ending Soon", value: "EXPIRES_SOON"}
]

export const RARITIES_FILTRATION_DATA = {
    name: "rarities",
    filedName: "rarities",
    open: false,
    attributes: [
        {name: "Common", id: 1, checked: false},
        {name: "Divine", id: 2, checked: false},
        {name: "Rare", id: 3, checked: false},
        {name: "Legendary", id: 4, checked: false},
        {name: "Elite", id: 5, checked: false},
        {name: "Ordinary", id: 6, checked: false},
    ]
}

export const GROUPS_FILTRATION_DATA = {
    name: "categories",
    filedName: "groups",
    open: true,
    attributes: [
        {name: "Outfits", id: "Outfits", checked: false},
        {name: "Tops", id: "Tops", checked: false},
        {name: "Bottoms", id: "Bottoms", checked: false},
        {name: "Feet", id: "Feet", checked: false},
        {name: "Stickers", id: "Stickers", checked: false},
        {name: "Vehicles", id: "Vehicles", checked: false},
        {name: "Weapons", id: "Weapons", checked: false},
        {name: "Utility", id: "Utility", checked: false},
        {name: "Player Cards", id: "Player_Card", checked: false},
        {name: "Player Titles", id: "Player_Title", checked: false},
        {name: "Backgrounds", id: "Avatar_Background", checked: false},
        {name: "Throwables", id: "Throwables", checked: false}
    ]
}

export const CHAINS_FILTRATION_DATA = {
    name: "chains",
    filedName: "chains",
    open: false,
    attributes: [
        {name: "Base", id: CHAIN_ID.BASE_MAINNET.toString(), checked: false},
        {name: "Ethereum", id: CHAIN_ID.ETH_MAINNET.toString(), checked: false},
    ]
}

export const CHART_PERIODS = ["1M", "3M", "6M", "YTD", "ALL"]

export const CHART_DEFAULT_DATA = [{date: "", close: 0}]

export const REG_EXP = {
    EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    EMAILS: /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!mail.ru)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/g,
    PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[\x20-\x7E]{8,}$/
}

export const WALLET_ERROR_CODE = {
    UNRECOGNIZED_CHAIN_ID: 4902,
    USER_REJECT_REQUEST: 4001
}