import React from 'react';
import {useTranslation} from "react-i18next";
import {CountDownWithContainer} from "components/molecules";

const DeadlineCountdown = ({data, isLoading}) => {
    const {t} = useTranslation()

    const inventoryExpirationDate = data?.expiration_date || false

    const countDownText = !isLoading && data ? t("offerExpires") : t("offerIs")

    return (
        <div className="shop-divine_content_deadline">
            <CountDownWithContainer
                day={true}
                loading={isLoading}
                text={countDownText}
                success={!isLoading && data}
                date={inventoryExpirationDate}
            />
        </div>
    )
}

export default DeadlineCountdown