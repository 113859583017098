import React from 'react';
import {useTranslation} from "react-i18next";
import {SkeletonText} from "components/atoms";

const Description = ({data, isLoading}) => {
    const {t} = useTranslation()

    return (
        <div className="shop-divine_content_description">
            <span className="shop-divine_content_description_title">{t("description")}</span>
            {isLoading
                ? <SkeletonText textLineCount={5}/>
                : data
                    ? <p>{data.inventory.description}</p>
                    : <p>{t("divineClosedDescription")}</p>}
        </div>
    )
}

export default Description
