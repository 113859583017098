import {HelmetProvider} from "react-helmet-async";
import React from "react";
import "reflect-metadata"
import './i18';

import {GlobalModalsProvider} from "./layouts";
import Routers from "./routes";

function App() {
    return (
        <React.Fragment>
            <HelmetProvider>
                <GlobalModalsProvider>
                    <Routers/>
                </GlobalModalsProvider>
            </HelmetProvider>
        </React.Fragment>
    );
}

export default App;
