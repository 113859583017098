import React, {memo} from "react";
import {useTranslation} from "react-i18next";

import ProfileLanguageSelectBlock from "./ProfileLanguageSelectBlock";
import ProfilePasswordBlock from "./ProfilePasswordBlock";
import ProfileNicknameBlock from "./ProfileNicknameBlock";
import ProfileWalletBlock from "./ProfileWalletBlock";
import ProfileAvatarBlock from "./ProfileAvatarBlock";
import ProfileEmailBlock from "./ProfileEmailBlock";
import "./style.scss"

const ProfileSetting = () => {
    const {t} = useTranslation()

    return (
        <section className="profile">
            <div className="profile_container">
                <h1>{t("profilePageTitle")}</h1>
                <ProfileAvatarBlock/>
                <ProfileNicknameBlock/>
                <ProfileEmailBlock/>
                <ProfileLanguageSelectBlock/>
                <ProfilePasswordBlock/>
                <ProfileWalletBlock/>
            </div>
        </section>
    )
}

export default memo(ProfileSetting)