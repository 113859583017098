import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import React, {memo} from "react";

import classNames from "classnames";

import {formatNumberToEuropeanStyle} from "utils";

import SwitchBlock from "../UserDetails/SwitchBlock";

import {png} from "assets/png";

const MenuUser = ({signOut}) => {
    const navigate = useNavigate()
    const {
        wallet,
        picture,
        nickname,
        voolahBalance,
        kompeteBalance
    } = useSelector(state => state.authV2.signIn.userData)
    const {t} = useTranslation()

    const noRegisteredWallet = classNames({"no-wallet": !wallet})

    return (
        <div className="navigation-mobile_content_user-menu">
            <img alt="avatar" src={picture ? picture : png.NewAvatar}/>
            <h3>{nickname}</h3>
            <div className="navigation-mobile_content_user-menu_balance">
                <SwitchBlock/>
                <div>
                    <img alt="voolah" src={png.VoolahCoin}/>
                    <span>{formatNumberToEuropeanStyle(voolahBalance)}</span>
                </div>
                <div className={noRegisteredWallet}>
                    <img alt="kompete-token" src={png.T1Png}/>
                    <span>{formatNumberToEuropeanStyle(kompeteBalance ? Number(kompeteBalance).toFixed(2) : 0)}</span>
                </div>
            </div>
            <div className="navigation-mobile_content_user-menu_options">
                <button onClick={() => navigate("/profile")}>{t("profile")}</button>
                {/*<button disabled={!isConnected} onClick={() => disconnectWallet()}>Wallet Disconnect</button>*/}
                <button onClick={() => signOut()}>{t("logOut")}</button>
            </div>
        </div>
    )
}

export default memo(MenuUser)