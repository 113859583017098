export const SERVER = {
    MASTER: "ONE",
    MARKET: "TWO",
    XSOLLA_STORE: "THREE",
    LOCAL: "FOUR"
}

export const INVENTORY_TYPE = {
    WEB3: "web3",
    WEB2: "web2"
}

export const ATTRIBUTES_EXTERNAL_ID = {
    RARITY: "rarity",
    WEB2_OR_WEB3: "web2_or_web3",
}

export const ATTRIBUTES_VALUE_EXTERNAL_ID = {
    WEB2: "web_2",
    WEB3_ETH: "web_3",
    WEB3_BASE: "web3_8453",
}

export const CHAIN_ID = {
    ETH_MAINNET: 1,
    ETH_SEPOLIA: 11155111,
    BASE_MAINNET: 8453,
    BASE_SEPOLIA: 84532
}

export const CARD_CHAIN_ICON = {
    [CHAIN_ID.ETH_MAINNET]: "ethChain",
    [CHAIN_ID.ETH_SEPOLIA]: "ethChain",
    [CHAIN_ID.BASE_MAINNET]: "baseChain",
    [CHAIN_ID.BASE_SEPOLIA]: "baseChain"
}

export const INVENTORY_CORRESPOND_END = {
    [CHAIN_ID.ETH_MAINNET]: "-web3",
    [CHAIN_ID.ETH_SEPOLIA]: "-web3",
    [CHAIN_ID.BASE_MAINNET]: "-web3-8453",
    [CHAIN_ID.BASE_SEPOLIA]: "-web3-8453",
}

export const TRANSACTION_TYPE = {
    MINT: "Mint",
    TRANSFER: "Transfer",
    SOLD: "Sold"
}

export const USER_LOCK_TIME = {
    MINUTES_15: 15,
    MINUTES_10: 10,
    MINUTES_60: 60,
    YEAR_1: 525600
}

export const LOCK_SECTION = {
    KONVERT: "konvert"
}

export const STEPS_STATUS = {
    //GLOBAL
    CLOSE: "",
    LOGIN: "login",
    ORDER: "order",
    REJECT: "reject",
    PROCESS: "process",
    SUCCESS: "success",
    WALLET_SUCCESS: "wallet-success",

    //LOCKER
    TRANSFER: "transfer",
    LISTING_SUCCESS: "listing-success",
    KONVERT_SUCCESS: "konvert-success",
    KONVERT_COOLDOWN: "konvert-cooldown",
    KONVERT_COOLDOWN_V2: "konvert-cooldown-v2",
    TRANSFER_SUCCESS: "transfer-success",


    //BAZAAR
    ORDER_EDIT: "order-edit",
    ORDER_REMOVE: "order-remove",
    ORDER_EDIT_SUCCESS: "order-edit-success",
    ORDER_TRANSACTION_FAIL: "order-trans-fail",
    ORDER_CANCEL_SUCCESS: "order-cancel-success",
    ORDER_PURCHASE_SUCCESS: "order-purchase-success",

    //DIVINE
    WEB2_BROWSER: "web2-browser",

    //LIMITED


    //CLAIM
    CLAIM_SUCCESS: "claim-success",

    //AUTH STEPS
    SIGN_IN: "step1"
}

export const BANNER = {
    DISCORD: "discord"
}

export const STATUS = {
    NONE: "",
    ERROR: "error",
    DEFAULT: "default",
    SUCCESS: "success",
}

export const GLOBAL_MODALS = {
    CLOSE: null,
    ERROR: "error",
    PROCESS: "process",
    SUCCESS: "success",
    SIGN_IN: "sign_in",
    LOGIN_ALERT: "login_alert",
    CONNECT_WALLET: "connect_wallet",
    CREATE_ACCOUNT: "create_account",
    CHANGE_PASSWORD: "change_password",
    CREATE_NICKNAME: "create_nickname"
}

export const SIZE = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
}

export const BUTTON_VARIANT = {
    RED: "red",
    DEFAULT: "default",
    REMOVED: "removed",
    LOADING: "loading"
}

export const INPUT_VARIANT = {
    DEFAULT: "default",
}

export const INPUT_TYPE = {
    TEXT: "text",
    NUMBER: "number",
    PASSWORD: "password"
}

export const AUTH_NETWORK = {
    APPLE: "apple",
    EMAIL: "email",
    GOOGLE: "google",
    TWITTER: "twitter",
    FACEBOOK: "facebook"
}

export const POSITION = {
    LEFT: "left",
    RIGHT: "right",
}