import React from 'react';
import {useTranslation} from "react-i18next";

import {QuantityCalculator, Icon} from "components/atoms";
import {checkAndFixNumber, PAYMENT_TYPE} from "utils";

const PriceInfo = ({quantity, setQuantity, data, isLoading}) => {
    const {t} = useTranslation()

    const DEFAULT_PRICE_TEXT = "??????????????";

    const calculatePriceDisplay = (isLoading, isDataAvailable, inventoryPrice, quantity) => {
        if (isLoading) return "";
        if (!isDataAvailable) return DEFAULT_PRICE_TEXT;
        return checkAndFixNumber(inventoryPrice, 3) * quantity;
    };

    const {inventory, payment} = data || {}

    const isKompetePayment = payment === PAYMENT_TYPE.KOMPETE;
    const inventoryPrice = isKompetePayment ? inventory?.kompete_price : inventory?.eth_price
    const paymentIcon = isKompetePayment ? <Icon name="kompete"/> : <Icon name="ethereumG"/>

    return (
        <div className="shop-divine_content_price-info">
            <div className="shop-divine_content_price-info_price">
                <span className="shop-divine_content_price-info_price_title">{t("priceFor")} {quantity}</span>
                <div className="shop-divine_content_price-info_price_content">
                    {paymentIcon}
                    <span className={isLoading ? "skeleton" : ""}>
                                {calculatePriceDisplay(isLoading, data, inventoryPrice, quantity)}
                    </span>
                </div>
            </div>
            {!isLoading && data &&
                <div className="shop-divine_content_price-info_quantity">
                    <span className="shop-divine_content_price-info_quantity_title">{t("quantity")}</span>
                    <QuantityCalculator value={quantity} setValue={setQuantity} limit={5}/>
                </div>}
        </div>
    )
}

export default PriceInfo