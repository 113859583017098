import React from "react"
import {useTranslation} from "react-i18next";

import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";

import {png} from "assets/png";

const HomeEngine = () => {
    const {t} = useTranslation()

    return (
        <section className="home_unreal-engine">
            <Title underline={true}>{t("engineNoteTitlePartOne")} <span>{t("engineNoteTitlePartTwo")}</span></Title>
            <div className="home_unreal-engine_content container">
                <div className="home_unreal-engine_content_engine-img">
                    <Image alt="unrealEngine" src={png.M11}/>
                </div>
                <div className="home_unreal-engine_content_info">
                    <div className="home_unreal-engine_content_info_left">
                        <h2>{t("engineNoteCreatedByTextPartOne")}
                            <br/><span>{t("engineNoteCreatedByTextPartTwo")}</span></h2>
                        <p>{t("engineNoteDeveloperNote")}</p>
                    </div>
                    <div className="home_unreal-engine_content_info_right">
                        <div>
                            <h2>{t("engineNoteCreatedByTextPartOne")}
                                <br/><span>{t("engineNoteCreatedByTextPartTwo")}</span></h2>
                            <div className="home_unreal-engine_content_info_right_img">
                                <Image alt="modernize" src={png.LogoModernizeGame}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeEngine