import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import {BUTTON_VARIANT, ERROR, GLOBAL_MODALS, INPUT_TYPE, REG_EXP} from "utils";
import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {userSignUp, reset} from "store/AuthV2/AuthV2Slice";
import {useConnectedWallet, useConnectWallet} from "hooks";

import {InputV2, Modal, ButtonV2} from "components/atoms";

import "./style.scss"

const SignUpModal = () => {
    const dispatch = useDispatch()

    const {t} = useTranslation()
    const {setModal} = useGlobalModalsContext()
    const {disconnectWallet} = useConnectWallet()
    const {isConnected} = useConnectedWallet()

    const {
        error: signUpError,
        loading: signUpLoading,
        success: signUpSuccess,
        errorMessage: signUpErrorMessage
    } = useSelector(state => state.authV2.signUp)

    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    useEffect(() => followSignUpProcess(), [signUpError, signUpSuccess]);

    const followSignUpProcess = () => {
        if (signUpSuccess) {
            if (isConnected) disconnectWallet()
            setModal(GLOBAL_MODALS.CONNECT_WALLET)
        }

        if (signUpError) setError(signUpErrorMessage)

        dispatch(reset("signUp"))
    }

    const signUp = () => {
        setError(ERROR.CLEAN)

        const validations = [
            {isValid: email.match(REG_EXP.EMAIL), error: ERROR.INVALID_EMAIL},
            {isValid: password.match(REG_EXP.PASSWORD), error: ERROR.INVALID_PASSWORD}
        ];

        for (const {isValid, error} of validations) {
            if (!isValid) {
                setError(error);
                return;
            }
        }

        dispatch(userSignUp({email, password, terms: true, offers: true, is13years: true,}))
    }

    const handleClick = () => {
        setModal(GLOBAL_MODALS.CLOSE)
    };

    return (<Modal
        isOpen={true}
        title={t("signupModalTitle")}
        onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
    >
        <div className="sign-up-modal">
            <InputV2
                name="email"
                value={email}
                errorMessage={error}
                disabled={signUpLoading}
                label={t("signupModalFormEmailLabel")}
                onChange={(e) => setEmail(e.target.value)}
            />
            <InputV2
                isError={error}
                name="password"
                value={password}
                disabled={signUpLoading}
                type={INPUT_TYPE.PASSWORD}
                label={t("signupModalFormPasswordLabel")}
                onChange={(e) => setPassword(e.target.value)}
            />
            <p>{t("signupModalAgreementTextPartOne")} <Link to="/terms" onClick={handleClick}>{t("terms")}
            </Link>{t("and")}<Link to="/privacy" onClick={handleClick}>{t("privacyPolicy")}</Link>
                {t("signupModalAgreementTextPartTwo")}</p>
            <ButtonV2
                onClick={signUp}
                disabled={!email || !password}
                variant={signUpLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
            >
                {t("signupModalFormSignUpButton")}
            </ButtonV2>
        </div>
    </Modal>)
}

export default SignUpModal