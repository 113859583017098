import React, {memo} from "react";
import {useTranslation} from "react-i18next";

const Congratulation = () => {
    const {t} = useTranslation()

    return (
        <div className="free-item-section_content_congratulations">
            <h1>{t("freeItemPageTitle")}</h1>
            <h4>{t("freeItemPageClaimedText")}</h4>
        </div>
    )
}

export default memo(Congratulation)