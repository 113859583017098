import React from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

import CheckBox2 from "components/atoms/CheckBox2";
import {Icon,Rarity,Image} from "components/atoms";

import {svg} from "assets/svg";
import {png} from "assets/png";
import "./styles.scss"

const PaymentMethod = ({onClick, checked, method, balance, recommended, insufficient, disable}) => {
    const balanceStyle = classNames("payment-method-block_left_currency_balance", {insufficient: insufficient})
    const paymentBlockStyle = classNames("payment-method-block", {insufficient: insufficient, checked: checked})
    const {t} = useTranslation()

    return (
        <div onClick={onClick} className={paymentBlockStyle}>
            <div className="payment-method-block_left">
                <div className="payment-method-block_left_img">
                    {
                        {
                            "eth":
                                <Icon name="ethereumG"/>,
                            "voolah":
                                <Image className="voolah" src={png.VoolahCoin} alt="voolah"/>,
                            "xsolla":
                                <Image className="xsolla" src={png.Xsolla} alt="xsolla"/>,
                            "kompete":
                                <Icon name="kompete"/>
                        }[method]
                    }
                </div>
                <div className="payment-method-block_left_currency">
                    <div className="payment-method-block_left_currency_name">
                        <span>
                            {
                                {
                                    "eth":
                                        "Ethereum",
                                    "voolah":
                                        "Voolah",
                                    "xsolla":
                                        "Xsolla",
                                    "kompete":
                                        "Kompete"
                                }[method]
                            }
                        </span>
                        {
                            recommended &&
                            <Rarity rarity="common" text={t("recommended")}/>
                        }
                    </div>
                    <div className={balanceStyle}>
                        {
                            method !== "xsolla" &&
                            <p>{t("currentBalance")}: {balance} <span>{method}</span></p>
                        }
                    </div>
                    <div className="payment-method-block_left_currency_info">
                        <Image src={svg.Currency} alt="cur."/>
                        {
                            {
                                "kompete":
                                    <span>{t("limitedInventoryOrderModalFees")}</span>,
                                "eth":
                                    <span>{t("limitedInventoryOrderModalFees")}</span>,
                                "voolah":
                                    <span>{t("limitedInventoryOrderModalCurrency")}</span>,
                                "xsolla":
                                    <span>{t("payWithCardOrCryptocurrency")}</span>,
                            }[method]
                        }
                    </div>
                </div>
            </div>
            <div className="payment-method-block_right">
                <CheckBox2 view="green" type="circle" checked={checked} onChange={onClick} disable={disable}/>
            </div>
        </div>
    )
}

export default PaymentMethod