import React from "react"
import Title from "components/atoms/TitleTemplate";
import "./style.scss"
import {useTranslation} from "react-i18next";

const data = [
    {
        name: "whyKompeteFeaturesOne",
        backgroundImage: "one"
    },
    {
        name: "whyKompeteFeaturesTwo",
        backgroundImage: "two"
    },
    {
        name: "whyKompeteFeaturesThree",
        backgroundImage: "three"
    },
    {
        name: "whyKompeteFeaturesFour",
        backgroundImage: "four"
    },
    {
        name: "whyKompeteFeaturesFive",
        backgroundImage: "five"
    },
    {
        name: "whyKompeteFeaturesSix",
        backgroundImage: "six"
    },
    {
        name: "whyKompeteFeaturesSeven",
        backgroundImage: "seven"
    },
    {
        name: "whyKompeteFeaturesEight",
        backgroundImage: "eight"
    },
    {
        name: "whyKompeteFeaturesNine",
        backgroundImage: "nine"
    },
    {
        name: "whyKompeteFeaturesTen",
        backgroundImage: "ten"
    },
    {
        name: "whyKompeteFeaturesEleven",
        backgroundImage: "eleven"
    },
    {
        name: "whyKompeteFeaturesTwelve",
        backgroundImage: "twelve"
    },
]

const HomeWhy = () => {
    const {t} = useTranslation()

    return (
        <section className="home_why-kompete">
            <Title underline={true}>{t("whyKompeteTitlePartOne")} <span>{t("whyKompeteTitlePartTwo")}</span></Title>
            <div className="home_why-kompete_content container">
                <div className="home_why-kompete_content_why-list">
                    {React.Children.toArray(
                        data.map((elm) =>
                            <WhyItem background={elm.backgroundImage}>{t(elm.name)}</WhyItem>
                        )
                    )}
                </div>
            </div>
        </section>
    )
}

const WhyItem = ({children, background}) => {
    return (
        <div className={`home_why-kompete_content_why-list_item ${background}`}>
            <div className="home_why-kompete_content_why-list_item_block">
                <h5>{children}</h5>
            </div>
        </div>
    )
}

export default HomeWhy