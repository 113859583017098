import {useSelector} from "react-redux";
import React, {memo} from 'react';

import ProfileInfoHeader from "./ProfileInfoHeader";
import ProfileInfoBlock from "./ProfileInfoBlock";

import "./style.scss"
import {useTranslation} from "react-i18next";

const ProfileEmailBlock = () => {
    const {userData} = useSelector(state => state.authV2.signIn)
    const {t} = useTranslation()

    return (
        <div className="profile_container_info_email">
            <ProfileInfoHeader title={t("email")}/>
            <ProfileInfoBlock text={userData.email}/>
        </div>
    )
}

export default memo(ProfileEmailBlock)