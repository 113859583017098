import ConnectWalletWhite from "./connectWallet-white.svg"
import VoolahLogoSmall from "./voolah-logo-small.svg"
import CoinMarket from "./coinmarketcap-dark.svg"
import MenuFilterGold from "./Group 533-gold.svg"
import AccordionDown from "./accordion-down.svg"
import MetamaskFront from "./metamask-front.svg"
import WalletConnect from "./walletconnect.svg"
import EtheriumGold from "./ethereum-gold.svg"
import SuccessMessage from "./success-not.svg"
import PriceHistory from "./price-history.svg"
import DiscordWhite from "./discord-white.svg"
import TelegramGold from "./telegram-gold.svg"
import RightAllPage from "./rightAllPage.svg"
import ComingBrand from "./coming-brand.svg"
import ProfileIcon from "./profile-icon.svg"
import RejectMessage from "./reject-not.svg"
import TwitterBlue from "./twitter-blue.svg"
import SupportLive from "./support-live.svg"
import AvatarCheck from "./avatar-check.svg"
import LeftAllPage from "./leftAllPage.svg"
import SearchBlack from "./searchBlack.svg"
import Group1White from "./group1White.svg"
import Group2White from "./group2White.svg"
import FacebookR from "./facebookRound.svg"
import TwitterRed from "./twitter-red.svg"
import FooterLogo from "./footer-logo.svg"
import SelectDown from "./select-down.svg"
import UnCheck from "./select-uncheck.svg"
import EditAvatar from "./edit-avatar.svg"
import CloseSmall from "./close-small.svg"
import YoutubeR from "./youtubeRound.svg"
import Group2Gold from "./group2Gold.svg"
import Coingecko from "./coingecko-2.svg"
import Group1Gold from "./group1Gold.svg"
import SearchHelp from "./searchHelp.svg"
import TwitterR from "./twitterRound.svg"
import WhyIcon10 from "./why-icon10.svg"
import WhyIcon11 from "./why-icon11.svg"
import WhyIcon12 from "./why-icon12.svg"
import MenuFilter from "./Group 533.svg"
import RightPage from "./rightPage.svg"
import Formatic from "./fortmatic.svg"
import Close from "./icons8-close.svg"
import Check from "./select-check.svg"
import WhyIcon1 from "./why-icon1.svg"
import WhyIcon2 from "./why-icon2.svg"
import WhyIcon3 from "./why-icon3.svg"
import WhyIcon4 from "./why-icon4.svg"
import WhyIcon5 from "./why-icon5.svg"
import WhyIcon6 from "./why-icon6.svg"
import WhyIcon7 from "./why-icon7.svg"
import WhyIcon8 from "./why-icon8.svg"
import WhyIcon9 from "./why-icon9.svg"
import Telegram from "./telegram.svg"
import Facebook from "./facebook.svg"
import MIcon_2 from "./why-icon4.svg"
import MIcon_10 from "./m-icon10.svg"
import Coinbase from "./coinbase.svg"
import LeftPage from "./leftPage.svg"
import Currency from "./currency.svg"
import Generate from "./generate.svg"
import Share2 from "./shareGold.svg"
import Support from "./support.svg"
import Youtube from "./youtube.svg"
import Twitter from "./twitter.svg"
import MIcon_9 from "./micon_9.svg"
import LogoMobile from "./logo.svg"
import Details from "./details.svg"
import Discord from "./discord.svg"
import Success from "./success.svg"
import Require from "./require.svg"
import KNoBack from "./KNoBack.svg"
import Secured from "./secured.svg"
import Tictok from "./tictok.svg"
import Google from "./google.svg"
import Offers from "./offers.svg"
import Delete from "./delete.svg"
import Listed from "./listed.svg"
import Avatar from "./avatar.svg"
import Market from "./market.svg"
import Reject from "./reject.svg"
import Minus from "./minus.svg"
import Email from "./email.svg"
import Apple from "./apple.svg"
import Logo from "./logo.svg"
import PLus from "./plus.svg"
import More from "./more.svg"
import Edit from "./edit.svg"
import List from "./list.svg"
import Date from "./date.svg"
import Fiat from "./fiat.svg"
import Xbox from "./xbox.svg"
import Yes from "./yes.svg"
import Eth from "./eth.svg"
import BNB from "./bnb.svg"
import No from "./no.svg"

import {ReactComponent as WalletDisconnect} from "./wallet-disconnect.svg";
import {ReactComponent as Walletconnect} from "./connectWallet-white.svg"
import {ReactComponent as FacebookStatic} from "./facebook-static.svg"
import {ReactComponent as TelegramStatic} from "./telegram-static.svg"
import {ReactComponent as TwitterXWhite} from "./twitter-x-white.svg"
import {ReactComponent as TelegramBlack} from "./telegram-black.svg"
import {ReactComponent as YoutubeStatic} from "./youtube-static.svg"
import {ReactComponent as DiscordStatic} from "./discord-static.svg"
import {ReactComponent as NoEligibility} from "./no-eligibility.svg"
import {ReactComponent as TelegramWhite} from "./telegram-white.svg"
import {ReactComponent as PasswordHide} from "./password-close.svg"
import {ReactComponent as TikTokStatic} from "./tiktok-static.svg"
import {ReactComponent as DiscordBlack} from "./discord-black.svg"
import {ReactComponent as PasswordOpen} from "./password-open.svg"
import {ReactComponent as SuccessCheck} from "./success-check.svg"
import {ReactComponent as DiscordBlue} from "./discord-blue.svg"
import {ReactComponent as SelectArrow} from "./select-arrow.svg"
import {ReactComponent as KompeteCoin} from "./kompete-coin.svg"
import {ReactComponent as LimitedTime} from "./limited-time.svg"
import {ReactComponent as ListedItems} from "./listed-items.svg"
import {ReactComponent as RightSymbol} from "./right-symbol.svg"
import {ReactComponent as XboxDefault} from "./xbox-default.svg"
import {ReactComponent as GooglePlay} from "./google-playe.svg"
import {ReactComponent as UnTradeable} from "./unTradeable.svg"
import {ReactComponent as Eligibility} from "./eligibility.svg"
import {ReactComponent as Playstation} from "./playstation.svg"
import {ReactComponent as EthereumG} from "./ethereum-gold.svg"
import {ReactComponent as VoolahLogo} from "./voolah-logo.svg"
import {ReactComponent as OwnedItems} from "./owned-items.svg"
import {ReactComponent as LeftSymbol} from "./left-symbol.svg"
import {ReactComponent as VoolahCoin} from "./voolah-coin.svg"
import {ReactComponent as LocationBack} from "./location.svg"
import {ReactComponent as Activities} from "./activities.svg"
import {ReactComponent as CloseIcon} from "./close-small.svg"
import {ReactComponent as PhoneBack} from "./phone-back.svg"
import {ReactComponent as BaseChain} from "./base-chain.svg"
import {ReactComponent as EpicGames} from "./epic-games.svg"
import {ReactComponent as NextArrow} from "./next-arrow.svg"
import {ReactComponent as PrevArrow} from "./prev-arrow.svg"
import {ReactComponent as KompeteLogo} from "./kompete.svg"
import {ReactComponent as Tradeable} from "./tradeable.svg"
import {ReactComponent as Modernize} from "./modernize.svg"
import {ReactComponent as Protected} from "./protected.svg"
import {ReactComponent as EthChain} from "./eth-chain.svg"
import {ReactComponent as SuccessSvg} from "./success.svg"
import {ReactComponent as TwitterX} from "./twitter-x.svg"
import {ReactComponent as Trust} from "./trust-wallet.svg"
import {ReactComponent as Select} from "./select-down.svg"
import {ReactComponent as CartLine} from "./cart-line.svg"
import {ReactComponent as AppStore} from "./app-store.svg"
import {ReactComponent as Cooldown} from "./cooldown.svg";
import {ReactComponent as Increase} from "./increase.svg"
import {ReactComponent as Activity} from "./activity.svg"
import {ReactComponent as Metamask} from "./metaMask.svg"
import {ReactComponent as GamePad} from "./game-pad.svg"
import {ReactComponent as Left} from "./ctrl-left 1.svg"
import {ReactComponent as GoogleSVG} from "./google.svg"
import {ReactComponent as Profile} from "./profile.svg"
import {ReactComponent as Display} from "./display.svg"
import {ReactComponent as Android} from "./android.svg"
import {ReactComponent as Refresh} from "./refresh.svg"
import {ReactComponent as Loading} from "./loading.svg"
import {ReactComponent as AppleSVG} from "./apple.svg"
import {ReactComponent as Weapon} from "./weapons.svg"
import {ReactComponent as EditV2} from "./edit-v2.svg"
import {ReactComponent as Window} from "./window.svg"
import {ReactComponent as Search} from "./search.svg"
import {ReactComponent as Divine} from "./divine.svg"
import {ReactComponent as Filter} from "./filter.svg"
import {ReactComponent as LogOut} from "./logout.svg"
import {ReactComponent as Ethereum} from "./eth.svg"
import {ReactComponent as Phone} from "./phone.svg"
import {ReactComponent as Price} from "./price.svg"
import {ReactComponent as ZhTW} from "./zh-TW.svg"
import {ReactComponent as ZhCN} from "./zh-CN.svg"
import {ReactComponent as Sort} from "./sort.svg"
import {ReactComponent as Fire} from "./fire.svg"
import {ReactComponent as Bot} from "./bot.svg"
import {ReactComponent as Pc} from "./pc.svg"
import {ReactComponent as De} from "./de.svg"
import {ReactComponent as En} from "./en.svg"
import {ReactComponent as Es} from "./es.svg"
import {ReactComponent as Fr} from "./fr.svg"
import {ReactComponent as Hi} from "./hi.svg"
import {ReactComponent as Id} from "./id.svg"
import {ReactComponent as It} from "./it.svg"
import {ReactComponent as Ja} from "./ja.svg"
import {ReactComponent as Ko} from "./ko.svg"
import {ReactComponent as Nl} from "./nl.svg"
import {ReactComponent as Pl} from "./pl.svg"
import {ReactComponent as Pt} from "./pt.svg"
import {ReactComponent as Ru} from "./ru.svg"
import {ReactComponent as Sv} from "./sv.svg"
import {ReactComponent as Th} from "./th.svg"
import {ReactComponent as Tr} from "./tr.svg"
import {ReactComponent as Vi} from "./vi.svg"

export const SVG = {
    walletDisconnect: WalletDisconnect,
    telegramBlack: TelegramBlack,
    walletConnect: Walletconnect,
    noEligibility: NoEligibility,
    twitterXWhite: TwitterXWhite,
    telegramWhite: TelegramWhite,
    discordBlack: DiscordBlack,
    passwordHide: PasswordHide,
    passwordOpen: PasswordOpen,
    locationBack: LocationBack,
    successCheck: SuccessCheck,
    eligibility: Eligibility,
    kompeteLogo: KompeteLogo,
    listedItems: ListedItems,
    telegram: TelegramStatic,
    facebook: FacebookStatic,
    rightSymbol: RightSymbol,
    unTradeable: UnTradeable,
    playstation: Playstation,
    discordBlue: DiscordBlue,
    selectArrow: SelectArrow,
    activities: Activities,
    ownedItems: OwnedItems,
    discord: DiscordStatic,
    youtube: YoutubeStatic,
    leftSymbol: LeftSymbol,
    voolahLogo: VoolahLogo,
    googlePlay: GooglePlay,
    phoneBack: PhoneBack,
    kompete: KompeteCoin,
    ethereumG: EthereumG,
    limited: LimitedTime,
    tiktok: TikTokStatic,
    tradeable: Tradeable,
    modernize: Modernize,
    epicGames: EpicGames,
    prevArrow: PrevArrow,
    nextArrow: NextArrow,
    baseChain: BaseChain,
    protected: Protected,
    success: SuccessSvg,
    ethChain: EthChain,
    metaMask: Metamask,
    voolah: VoolahCoin,
    activity: Activity,
    increase: Increase,
    twitterX: TwitterX,
    cartLine: CartLine,
    appStore: AppStore,
    cooldown: Cooldown,
    xbox: XboxDefault,
    google: GoogleSVG,
    twitter: TwitterX,
    gamePad: GamePad,
    display: Display,
    android: Android,
    refresh: Refresh,
    close: CloseIcon,
    profile: Profile,
    loading: Loading,
    injected: Trust,
    apple: AppleSVG,
    weapon: Weapon,
    window: Window,
    search: Search,
    divine: Divine,
    select: Select,
    filter: Filter,
    logOut: LogOut,
    editV2: EditV2,
    eth: Ethereum,
    phone: Phone,
    price: Price,
    "zh-TW":ZhTW,
    "zh-CN":ZhCN,
    sort: Sort,
    left: Left,
    fire: Fire,
    bot: Bot,
    pc: Pc,
    de:De,
    en:En,
    es:Es,
    fr:Fr,
    hi:Hi,
    id:Id,
    it:It,
    ja:Ja,
    ko:Ko,
    nl:Nl,
    pl:Pl,
    pt:Pt,
    ru:Ru,
    sv:Sv,
    th:Th,
    tr:Tr,
    vi:Vi,
}

export const svg = {
    ConnectWalletWhite,
    VoolahLogoSmall,
    MenuFilterGold,
    SuccessMessage,
    MetamaskFront,
    RejectMessage,
    WalletConnect,
    AccordionDown,
    EtheriumGold,
    RightAllPage,
    PriceHistory,
    DiscordWhite,
    TelegramGold,
    SupportLive,
    ComingBrand,
    ProfileIcon,
    LeftAllPage,
    SearchBlack,
    Group2White,
    Group1White,
    TwitterBlue,
    AvatarCheck,
    EditAvatar,
    CloseSmall,
    SelectDown,
    Group2Gold,
    FooterLogo,
    CoinMarket,
    LogoMobile,
    MenuFilter,
    SearchHelp,
    Group1Gold,
    TwitterRed,
    FacebookR,
    Coingecko,
    RightPage,
    WhyIcon10,
    WhyIcon11,
    WhyIcon12,
    WhyIcon1,
    WhyIcon3,
    WhyIcon4,
    WhyIcon5,
    WhyIcon6,
    WhyIcon7,
    WhyIcon8,
    WhyIcon9,
    Telegram,
    Facebook,
    WhyIcon2,
    LeftPage,
    MIcon_10,
    Coinbase,
    Formatic,
    Generate,
    TwitterR,
    YoutubeR,
    Currency,
    Youtube,
    MIcon_2,
    Twitter,
    MIcon_9,
    Details,
    UnCheck,
    Discord,
    Require,
    Support,
    Success,
    KNoBack,
    Secured,
    Market,
    Google,
    Reject,
    Tictok,
    Share2,
    Offers,
    Delete,
    Listed,
    Avatar,
    Close,
    Minus,
    Check,
    Email,
    Apple,
    Logo,
    Left,
    PLus,
    More,
    Edit,
    List,
    Date,
    Fiat,
    Xbox,
    Eth,
    Yes,
    BNB,
    No,
}
