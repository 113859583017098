import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import React from "react";

import {useAuthorization, useConnectedWallet, useConnectWallet, useDetectMobile} from "hooks";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {GLOBAL_MODALS, STEPS_STATUS} from "utils";

import {Button} from "components/atoms";

const BuyButton = ({data, quantity, isLoading, isEligibilityAvailable, isEligible}) => {
    const navigate = useNavigate()

    const {userData} = useSelector(state => state.authV2.signIn)

    const {isAuth, accessToken} = useAuthorization()
    const {isConnected,} = useConnectedWallet()
    const {setCurrentModal} = useModalsContext()
    const {openWalletModal} = useConnectWallet()
    const {isMobile} = useDetectMobile()
    const {setModal} = useGlobalModalsContext()
    const {t} = useTranslation()

    const isProviderAvailable = typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined'

    const determineOnClickAction = () => {
        if (!isAuth)
            return setModal(GLOBAL_MODALS.LOGIN_ALERT)

        if (isAuth && !userData.wallet)
            navigate("/profile")

        if (isEligibilityAvailable && !isEligible)
            return () => {
            }

        return isConnected
            ? setCurrentModal({
                status: STEPS_STATUS.ORDER,
                data: {inventory: data.inventory, quantity, payment: data.payment}
            })
            : isMobile ? isProviderAvailable ? openWalletModal() : setCurrentModal({status: STEPS_STATUS.WEB2_BROWSER}) : openWalletModal()
    }

    const determineDisplayText = () => {
        if (!isAuth)
            return accessToken ? "" : t("buyButtonText");

        if (isAuth && !userData.wallet)
            return "Add Wallet"

        if (isEligibilityAvailable && !isEligible)
            return "REQUIREMENTS NOT MET!";

        return isConnected ? t("buyButtonText") : t("connectWalletButtonText");
    }

    const buttonDetails = {
        onClick: () => determineOnClickAction(),
        text: () => determineDisplayText()
    }

    return (
        <React.Fragment>
            {!isLoading && data && (
                <Button onClick={() => buttonDetails.onClick()}>
                    {buttonDetails.text()}
                </Button>
            )}
        </React.Fragment>
    )
}

export default BuyButton