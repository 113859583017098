import {ZERO_BYTES32} from "@modernize-games/nft-sdk";
import {formatUnits, parseUnits} from "ethers/lib/utils";
import {ethers} from "ethers";

import {walletAndTransactionErrorHandle, ERROR, errorHandler, PAYMENT_TYPE} from "utils";
import {createOrderWithSignature, web3Utils} from "web3/utils";
import {useSelector} from "react-redux";

const useDivineShop = ({price, signer, quantity, tokenId, account, chainId, paymentType}) => {
    const {contractAddress} = useSelector(state => state.web3.currentChain)
    const createSellOrder = async () => {
        const {marketplace} = await web3Utils.getConfiguration(0, chainId)

        const {order: sellOrder, signature: sellSignature} = await createOrderWithSignature({
            chainId,
            token: paymentType.toLowerCase(),
            price,
            tokenId,
            amount: quantity,
            orderSide: "Sell",
            admin: true,
            mint: true,
            feePercent: 0
        })

        if (!(await marketplace.validateOrderParameters_(sellOrder)))
            errorHandler(ERROR.INVALID_SELL_ORDER_PARAMS)
        if (!(await marketplace.validateOrder_(sellOrder, sellSignature)))
            errorHandler(ERROR.INVALID_SELL_ORDER)


        return {sellOrder, sellSignature}
    }

    const createBuyOrder = async () => {
        const {marketplace, paymentToken} = await web3Utils.getConfiguration(0, chainId)

        if (paymentType === PAYMENT_TYPE.KOMPETE) {
            const decimals = await paymentToken.decimals()

            let allowance = await paymentToken.connect(signer).allowance(account, contractAddress.marketplace)

            const approveAmount = parseUnits('10000000000000000000', decimals)
            const hexDefaultValue = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

            if (allowance._hex === hexDefaultValue || allowance._hex === "0x00") {
                const approve = await paymentToken.connect(signer).approve(contractAddress.marketplace, approveAmount)
                await approve.wait()
            }

            allowance = await paymentToken.connect(signer).allowance(account, contractAddress.marketplace)
            const formattedAllowance = formatUnits(allowance, decimals)

            if ((Number(formattedAllowance) <= Number(price))) {
                const approve = await paymentToken.connect(signer).approve(contractAddress.marketplace, approveAmount)
                await approve.wait()

                if (formattedAllowance <= price) {
                    errorHandler(ERROR.ALLOWANCE_INVALID)
                }
            }
        }

        try {
            const {order: buyOrder, signature: buySignature} = await createOrderWithSignature({
                chainId,
                signer,
                token: paymentType.toLowerCase(),
                price,
                tokenId,
                amount: quantity,
                account,
                orderSide: "Buy",
                mint: true,
                feePercent: 0
            })

            if (!(await marketplace.validateOrderParameters_(buyOrder)))
                errorHandler(ERROR.INVALID_BUY_ORDER_PARAMS)
            if (!(await marketplace.validateOrder_(buyOrder, buySignature)))
                errorHandler(ERROR.INVALID_SELL_ORDER)

            return {buyOrder, buySignature}
        } catch (error) {
            walletAndTransactionErrorHandle(error)
        }
    }

    const sendTransaction = async (sellOrder, sellSignature, buyOrder, buySignature) => {
        const {marketplace} = await web3Utils.getConfiguration(0, chainId)
        const parsePrice = parseUnits(price.toString(), 18)

        try {
            if (!(await marketplace.ordersCanMatch_(buyOrder, sellOrder))) {
                if (await marketplace.ordersCanMatch_(buyOrder, sellOrder)) {
                    errorHandler(ERROR.INVALID_MATCH)
                }
            }

            let gasLimit

            try {
                gasLimit = (
                    await marketplace.estimateGas.atomicMatch_(
                        buyOrder,
                        buySignature,
                        sellOrder,
                        sellSignature,
                        ZERO_BYTES32,
                    )
                )
                    .mul(110)
                    .div(100)

            } catch (error) {
                gasLimit = ethers.BigNumber.from("245000")
            }

            try {
                const tx = await marketplace
                    .connect(signer)
                    .atomicMatch_(
                        buyOrder,
                        buySignature,
                        sellOrder,
                        sellSignature,
                        ZERO_BYTES32,
                        {
                            gasLimit,
                            ...(paymentType === PAYMENT_TYPE.ETH && {value: parsePrice}),
                        },
                    )

                return tx.wait().then((result) => result)
            } catch (error) {
                walletAndTransactionErrorHandle(error)
            }

        } catch (error) {
            walletAndTransactionErrorHandle(error)
        }
    }

    return {
        createSellOrder,
        createBuyOrder,
        sendTransaction
    }
}

export default useDivineShop




