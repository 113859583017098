import React from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const EligibilityBlock = ({isEligible}) => {
    const {t} = useTranslation()
    const blockStyle = classNames("shop-divine_content_eligibility-block", {eligible: isEligible})

    return (
        <div className={blockStyle}>
            <span>{isEligible ? t("eligible") : t("notEligible")}</span>
        </div>
    )
}

export default EligibilityBlock