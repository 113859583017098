import React from "react";
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const EconomyYouPay = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <section className="economy_you-pay">
            <Title underline={true}>{t("gameEconomyPayTitlePartOne")} <span>{t("gameEconomyPayTitlePartTwo")}</span></Title>
            <div className="economy_you-pay_options container">
                <OptionsItem
                    orgImage={png.VoolahEcoPng}
                    noOrgImage={png.VoolahEcoWebp}
                    title={t("gameEconomyPayOption1Name")}
                    onClick={() => navigate("/buy-kompete-token")}
                    buttonText={t("gameEconomyPayOption1ButtonText")}
                >
                    {t("gameEconomyPayOption1Description")}
                </OptionsItem>
                <OptionsItem
                    orgImage={png.EthereumEcoPng}
                    noOrgImage={png.EthereumEcoWebp}
                    title={t("gameEconomyPayOption2Name")}
                    onClick={() => navigate("/shop")}
                    buttonText={t("gameEconomyPayOption2ButtonText")}
                >
                    {t("gameEconomyPayOption2Description")}
                </OptionsItem>
            </div>
        </section>
    )
}
export default EconomyYouPay

const OptionsItem = ({orgImage, noOrgImage, title, onClick,buttonText, children}) => {
    return (
        <div className="economy_you-pay_options_item">
            <div className="economy_you-pay_options_item_img-block">
                <ProgressiveImage alt="currency" imgSrc={orgImage} previewSrc={noOrgImage}/>
            </div>
            <div className="economy_you-pay_options_item_content">
                <h2>{title}</h2>
                <p>{children}</p>
            </div>
            <Button view="secondary" onClick={onClick}>{buttonText}</Button>
        </div>
    )
}