import React from "react";
import {useTranslation} from "react-i18next";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import UnderLine from "components/atoms/UnderLine";
import {png} from "assets/png";

import "./style.scss"

const EconomyMain = () => {
    const {t} = useTranslation()

    return (
        <section className="economy_main">
            <div className="overlay-top"></div>
            <div className="economy_main_block">
                <div className="economy_main_block_left-part">
                    <h1><span>{t("gameEconomyTitlePartOne")}</span> {t("gameEconomyTitlePartTwo")}</h1>
                    <UnderLine/>
                    <p>{t("gameEconomyIntroductionText")}</p>
                </div>
                <div className="economy_main_block_right-part">
                    <ProgressiveImage
                        className="desktop-img"
                        imgSrc={png.GameEconomyCosmeticsPng}
                        previewSrc={png.GameEconomyCosmeticsWebp}
                    />
                </div>
            </div>
        </section>
    )
}
export default EconomyMain