import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {getPosts} from "store/NewsPosts/postSlice";

import ArticleCart from "components/atoms/ArticleCart";
import Title from "components/atoms/TitleTemplate";

import "./style.scss"

const PostNews = () => {
    const {posts, success} = useSelector(state => state.posts)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(getPosts({first: 3, skip: 0})), [])

    return (
        <section className="current-news-container_news">
            <Title underline={true}>{t("postTitlePartOne")} <span>{t("postTitlePartTwo")}</span></Title>
            <div className="container">
                {success &&
                    React.Children.toArray(
                        posts?.map((post) =>
                            <ArticleCart
                                date={post.date}
                                text={post.title}
                                img={post.image.url}
                                slug={post.slug}
                            />
                        )
                    )}
            </div>
        </section>
    )
}

export default PostNews