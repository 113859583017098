import crypto from "crypto-browserify"

export const decryptData = (encrypted) => {
    const cryptoSecretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY
    const cryptoIv = process.env.REACT_APP_CRYPTO_IV

    const savedSecretKey = Buffer.from(cryptoSecretKey, "hex")
    const savedIv = Buffer.from(cryptoIv, "hex")

    const decipher = crypto.createDecipheriv("aes-256-cbc", savedSecretKey, savedIv);

    let decrypted = decipher.update(encrypted, "hex", "utf-8");

    decrypted += decipher.final("utf-8");

    return JSON.parse(decrypted);
}