import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

import {reset} from "store/VoolahShop/voolahShopSlice"
import {STEPS_STATUS, voolahPackageDetails} from "utils";

import {ButtonV2, Image, Modal} from "components/atoms";
import {PaymentMethod} from "components/molecules";

import {png} from "assets/png";

const VoolahOrderModal = ({show, setCurrentModal, currentPackageIndex}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const cancelOrder = () => {
        setCurrentModal({status: STEPS_STATUS.CLOSE})
        dispatch(reset({section: "paymentUIvoolah"}))
    }

    const openWidget = () => {
        setCurrentModal({status: STEPS_STATUS.CLOSE})
        setTimeout(() => {
            window.XPayStationWidget.open()
        }, 10)
        dispatch(reset({section: "paymentUIvoolah"}))
    }

    return (
        <Modal
            isOpen={show}
            title={t("limitedInventoryOrderModalTitle")}
            onClose={() => cancelOrder()}
        >
            <div className="shop-voolah_order-summery_content">
                <div className="shop-voolah_order-summery_content_info">
                    <div className="shop-voolah_order-summery_content_info_img">
                        <Image alt="cosmetic" src={voolahPackageDetails[currentPackageIndex].src.small}/>
                    </div>
                    <div className="shop-voolah_order-summery_content_info_details">
                        <div className="shop-voolah_order-summery_content_info_details_title">
                            <Image alt="voolah" src={png.VoolahCoin}/>
                            <h5>{voolahPackageDetails[currentPackageIndex].value} Voolah</h5>
                        </div>
                    </div>
                </div>
                <div className="shop-voolah_order-summery_content_payments-methods">
                    <div className="shop-voolah_order-summery_content_payments-methods_head">
                        <div className="shop-voolah_order-summery_content_payments-methods_head_quantity">
                            <span>{t("limitedInventoryOrderModalPaymentMethod")}</span>
                            <span> (1)</span>
                        </div>
                    </div>
                    <PaymentMethod
                        method="xsolla"
                        checked={true}
                        recommended={true}
                        disable={true}
                    />
                </div>
                <div className="shop-voolah_order-summery_content_total">
                    <div>{t("total")}</div>
                    <div>${voolahPackageDetails[currentPackageIndex].price} USD</div>
                </div>
                <div className="shop-voolah_order-summery_content_buttons">
                    <ButtonV2 onClick={openWidget}>{t("payNow")}</ButtonV2>
                </div>
                <p className="shop-voolah_order-summery_content_terms">{t("voolahPaymentTerms")}</p>
            </div>
        </Modal>
    )
}

export default VoolahOrderModal