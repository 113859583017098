export const calculateVoolahPriceToKompete = async (price, isDynamic) => {
    const oneVoolahPrice = 0.01 //1 VOOLAH => USD

    if (isDynamic) {
        //@TODO will be integrated a dynamic logic in the future
    } else {
        const oneKompetePrice = 0.02 // 1 kompete => USD // hard code
        const inventoryVoolahPrice = Number(price.toString().replace(/,/g, "")) //VOOLAH

        return (inventoryVoolahPrice * oneVoolahPrice) / oneKompetePrice
    }
}