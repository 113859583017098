export const getDataFromCookies = (cookieName) => {
    const cookies = document.cookie.split(";")

    for (const cookie of cookies) {
        const [name, value] = cookie.trim().split("=")

        if(name === cookieName) {
            return decodeURIComponent(value)
        }
    }

    return null
}