import React from "react";
import {useTranslation} from "react-i18next";

import {useGlobalModalsContext} from "layouts";
import {GLOBAL_MODALS} from "utils";

import {ButtonV2, Modal} from "components/atoms";

import "./style.scss"

const LoginAlertModal = () => {
    const {setModal} = useGlobalModalsContext()
    const {t} = useTranslation()

    return (
        <Modal
            isOpen={true}
            onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
            title={t("loginAlertModalTitle")}
        >
            <div className="login-alert-modal">
                <ButtonV2 onClick={() => setModal(GLOBAL_MODALS.SIGN_IN)}>{t("login")}</ButtonV2>
                <ButtonV2 onClick={() => setModal(GLOBAL_MODALS.CREATE_ACCOUNT)}>{t("createAccount")}</ButtonV2>
            </div>
        </Modal>
    )
}

export default LoginAlertModal;