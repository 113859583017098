import React from "react";
import {useTranslation} from "react-i18next";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {ButtonV2, Icon, Modal} from "components/atoms";

import "./style.scss"

const SuccessModal = () => {
    const {message, data} = useGlobalModalsContext()
    const {t} = useTranslation()

    return (<Modal isOpen={true}>
        <div className="success-modal">
            <div className="success-modal_image-block">
                <Icon name="success"/>
            </div>
            <h3>{t("success")}!</h3>
            <p>{message}</p>
            {data && data?.onClick && <ButtonV2 onClick={data.onClick}>{data.text}</ButtonV2>}
        </div>
    </Modal>)
}

export default SuccessModal