import React from "react"
import {useTranslation} from "react-i18next";

import {BAZAAR_TABS} from "utils";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const TokenSix = ({setActiveTab}) => {
    const {t} = useTranslation()

    const gotToBazaar = () => {
        setActiveTab(BAZAAR_TABS.ITEMS)
        window.scrollTo(0, 0)
    }

    return (
        <section className="token-six">
            <Image className="c1" alt="coin" src={png.C1}/>
            <Image className="c3" alt="coin" src={png.C3}/>
            <Image className="c2" alt="coin" src={png.C2}/>
            <div className="container">
                <Title underline={true}>{t("tokenTabUtilityTitlePartOne")}<br/><span>{t("tokenTabUtilityTitlePartTwo")}</span></Title>
                <div className="content">
                    <div>
                        <h3>{t("tokenTabUtilityTitleArticle1TitlePartOne")}<br/><span> {t("tokenTabUtilityTitleArticle1TitlePartTwo")}</span></h3>
                        <p>{t("tokenTabUtilityTitleArticle1Content")}</p>
                        <Button view="secondary" onClick={gotToBazaar}>{t("tokenTabUtilityArticle1ButtonText")}</Button>
                    </div>
                    <div>
                        <ProgressiveImage
                            alt="bazaar"
                            imgSrc={png.BazaarRarityTokenPng}
                            previewSrc={png.BazaarRarityTokenWebp}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenSix