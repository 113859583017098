export const SUPPORTED_LANGUAGES = ["en", "de", "fr", "hi", "id", "it", "ja", "ko", "ru", "nl", "pl", "pt", "sv", "th", "tr", "vi", "zh-CN", "zh-TW", "es"]

export const COUNTRY_TO_LANGUAGE_MAP = {
    // Original countries
    US: "en",     // United States - English
    DE: "de",     // Germany - German
    FR: "fr",     // France - French
    IN: "hi",     // India - Hindi
    ID: "id",     // Indonesia - Indonesian
    IT: "it",     // Italy - Italian
    JP: "ja",     // Japan - Japanese
    KR: "ko",     // South Korea - Korean
    RU: "ru",     // Russia - Russian
    NL: "nl",     // Netherlands - Dutch
    PL: "pl",     // Poland - Polish
    PT: "pt",     // Portugal - Portuguese
    SE: "sv",     // Sweden - Swedish
    TH: "th",     // Thailand - Thai
    TR: "tr",     // Turkey - Turkish
    VN: "vi",     // Vietnam - Vietnamese
    CN: "zh-CN",  // China - Simplified Chinese
    TW: "zh-TW",  // Taiwan - Traditional Chinese

    // South America (only those with available languages):
    BR: "pt",     // Brazil - Portuguese
    GY: "en",     // Guyana - English
    SR: "nl",     // Suriname - Dutch
    GF: "fr",     // French Guiana (France) - French

    // English-speaking countries (since we have "en"):
    GB: "en",     // United Kingdom - English
    CA: "en",     // Canada - English majority (co-official French)
    AU: "en",     // Australia - English
    NZ: "en",     // New Zealand - English
    IE: "en",     // Ireland - English (co-official Irish)

    // German-speaking countries (we have "de"):
    AT: "de",     // Austria - German
    CH: "de",     // Switzerland - German majority (co-official fr, it, but German is largest)
    LI: "de",     // Liechtenstein - German

    // French-speaking countries (we have "fr"):
    // Belgium and Luxembourg are multilingual:
    // Belgium: Dutch majority (~60%), French (~39%), German (~1%)
    // We'll choose Dutch for Belgium since it's the majority and we have "nl" already defined.
    BE: "nl",     // Belgium - Dutch majority

    // Luxembourg: Luxembourgish, French, German are official.
    // Luxembourgish not in our list, pick French (next most commonly used administrative language).
    LU: "fr",     // Luxembourg - French

    // Hindi-speaking outside India:
    // Fiji (FJ) has English, Fijian, and Fiji Hindi as official languages.
    FJ: "hi",     // Fiji - Fiji Hindi is an official language

    // Portuguese-speaking countries:
    // We already have PT (Portugal) and BR (Brazil).
    // Add other Portuguese-speaking countries (Lusophone):
    AO: "pt",     // Angola - Portuguese
    MZ: "pt",     // Mozambique - Portuguese
    CV: "pt",     // Cape Verde - Portuguese
    GW: "pt",     // Guinea-Bissau - Portuguese
    ST: "pt",     // Sao Tome and Principe - Portuguese
    TL: "pt",     // Timor-Leste - Portuguese (co-official Tetum)

    // Swedish outside Sweden:
    // Finland (FI) has Finnish and Swedish as official. Finnish majority, but Swedish is also official.
    FI: "sv",     // Finland - Swedish (co-official)

    // Turkish outside Turkey:
    // Cyprus (CY) has Greek and Turkish as official languages.
    // Greek is not in our list, Turkish is.
    CY: "tr",     // Cyprus - Turkish (co-official)

    // Chinese outside Mainland China and Taiwan:
    // Singapore (SG) has English, Malay, Mandarin Chinese (zh-CN), Tamil.
    // We'll pick Mandarin Chinese since we have zh-CN.
    SG: "zh-CN",  // Singapore - Mandarin Chinese (co-official)

    // Hong Kong (HK) and Macau (MO) are special administrative regions:
    // Hong Kong: Chinese (Traditional) and English
    HK: "zh-TW",  // Hong Kong - Traditional Chinese

    // Macau: Chinese (Traditional) and Portuguese
    MO: "zh-TW",  // Macau - Traditional Chinese

    // Korean outside South Korea:
    // North Korea (KP) - Korean
    KP: "ko"      // North Korea - Korean
};