import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useState, memo} from "react";

import {globalService} from "store/Global/globalService";
import {useAuthorization, useDebounce} from "hooks";
import {ERROR, STATUS, SUCCESS, wait} from "utils";
import {setData} from "store/AuthV2/AuthV2Slice"

import ProfileInfoHeader from "./ProfileInfoHeader";
import {Input, ButtonV2} from "components/atoms";

const ProfileNicknameBlock = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const [processInfo, setProcessInfo] = useState({status: STATUS.DEFAULT, message: ""})
    const [isNicknameAddedSuccessfully, setIsNicknameAddedSuccessfully] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const {userData} = useSelector(state => state.authV2.signIn);

    const debounce = useDebounce()
    const {accessToken} = useAuthorization()

    const [nickname, setNickname] = useState(userData.nickname)
    const [isDisable, setIsDisable] = useState(true)

    const updateProcessInfo = (status, message) => setProcessInfo({status, message})

    const checkNickname = async (nickname) => {
        setIsDisable(true)
        setIsLoading(true)

        try {
            const isSameNickname = nickname === userData.nickname;
            const isEmptyNickname = !nickname;

            if (isSameNickname || isEmptyNickname) {
                setIsLoading(false)
                updateProcessInfo(STATUS.DEFAULT);
                return;
            }

            await globalService.checkNickname(nickname)

            setIsDisable(false)
            setIsLoading(false)
            updateProcessInfo(STATUS.SUCCESS, SUCCESS.NICKNAME_VALID);
        } catch (error) {
            setIsLoading(false)
            updateProcessInfo(STATUS.ERROR, error?.message ?? ERROR.WRONG)
        }
    }

    const handleNicknameChange = (value) => {
        setNickname(value)
        debounce(() => checkNickname(value), 700)
    }

    const updateNickname = async () => {
        setIsDisable(true)
        setIsLoading(true)

        try {
            await globalService.updateNickname(nickname, accessToken)

            dispatch(setData({path1: "signIn", path2: "userData", data: {...userData, nickname}}))
            updateProcessInfo(STATUS.DEFAULT, SUCCESS.NICKNAME_UPDATED)
            setIsNicknameAddedSuccessfully(true)
            setIsLoading(false)

            await wait(3000)

            setIsNicknameAddedSuccessfully(false)
            updateProcessInfo(STATUS.DEFAULT)
        } catch (error) {
            setIsLoading(false)
            updateProcessInfo(STATUS.ERROR, error?.message ?? ERROR.WRONG)
        }
    }

    return (
        <div className="profile_container_info_nickname">
            <ProfileInfoHeader title={t("nickname")} status={processInfo.status} processInfo={processInfo.message}/>
            <Input
                value={nickname}
                disabled={isDisable}
                onChange={(e) => handleNicknameChange(e.target.value)}
                icon={isNicknameAddedSuccessfully ? "successCheck" : "editV2"}
            />
            <ul className="profile_container_info_nickname_list">
                <li>{t("profilePageNicknameRequirement1")}</li>
                <li>{t("profilePageNicknameRequirement2")}</li>
            </ul>
            <ButtonV2
                loading={isLoading}
                disabled={isDisable}
                onClick={updateNickname}
            >
                {t("profilePageNicknameUpdateButtonText")}
            </ButtonV2>
        </div>
    )
}

export default memo(ProfileNicknameBlock)