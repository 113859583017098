import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import React, {Suspense, useEffect} from "react";
import {useDispatch} from "react-redux";

import {CHAINS_ENVIRONMENT, SUPPORTED_CHAINS} from "../utils";
import {useAuthorization, useConnectedWallet} from "../hooks";
import {switchCurrentChain} from "../store/Web3/web3Slice";
import {setBalance} from "../store/AuthV2/AuthV2Slice";
import {routes} from "./routes";

import GlobalModals from "../components/organisms/GlobalModals";
import {LoadSpinnerAllPage} from "../components/atoms";

const Routers = () => {
    const {connectedChainId, getEthBalance, getKompeteBalance, address} = useConnectedWallet()
    const dispatch = useDispatch()
    const {isAuth} = useAuthorization()
    // const {t} = useTranslation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        chainAndBalancesCheck()
        // checkingCurrentLanguage()
    }, [connectedChainId]);

    const chainAndBalancesCheck = () => {
        if (connectedChainId && SUPPORTED_CHAINS[CHAINS_ENVIRONMENT].includes(connectedChainId)) {
            dispatch(switchCurrentChain({chainId: connectedChainId}))
            getKompeteBalance(address, connectedChainId)
            getEthBalance(address, connectedChainId)
        }

        if (isAuth && !connectedChainId) {
            dispatch(setBalance({currencyType: "ethBalance", balance: 0}))
            dispatch(setBalance({currencyType: "kompeteBalance", balance: 0}))
        }
    }

    return (
        <Suspense fallback={<LoadSpinnerAllPage show={true}/>}>
            <Router>
                <Routes>
                    {React.Children.toArray(
                        routes.map(({path, element}) =>
                            <Route path={path} element={element}/>
                        )
                    )}
                </Routes>
                <GlobalModals/>
            </Router>
        </Suspense>
    )
}

export default Routers
