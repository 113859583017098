import React from "react";
import {useTranslation} from "react-i18next";

import {Image} from "components/atoms";
import {png} from "assets/png";

const TableNoBody = ({headLength}) => {
    const {t} = useTranslation()

    return (
        <tbody>
        <tr>
            <td className="table-container_main_no-body" colSpan={headLength}>
                <Image alt="reject" src={png.SadSmile}/>
                <p>{t("noActivity")}</p>
            </td>
        </tr>
        </tbody>
    )
}

export default TableNoBody