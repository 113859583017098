import React from "react"
import {useTranslation} from "react-i18next";

import Image from "components/atoms/Image";
import {png} from "assets/png";
import "./style.scss"

const TokenSeven = () => {
    const {t} = useTranslation()

    return (
        <section className="token-seven">
            <Image className="c5" alt="coin" src={png.C5}/>
            <Image className="c6" alt="coin" src={png.C6}/>
            <Image className="c3" alt="coin" src={png.C3}/>
            <div className="container">
                <div className="content">
                    <div>
                        <Image alt="badger" src={png.KompeteBadger}/>
                    </div>
                    <div>
                        <h2>{t("tokenTabUtilityArticle2TitlePartOne")} <span>{t("tokenTabUtilityArticle2TitlePartTwo")}</span></h2>
                        <div>
                            <div><span>1</span></div>
                            <div>{t("tokenTabUtilityArticle2Point1")}</div>
                        </div>
                        <div>
                            <div><span>2</span></div>
                            <div>{t("tokenTabUtilityArticle2Point2")}</div>
                        </div>
                        <div>
                            <div><span>3</span></div>
                            <div>{t("tokenTabUtilityArticle2Point3")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenSeven