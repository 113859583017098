import React from "react";
import {useTranslation} from "react-i18next";

import "./style.scss"

const HomeComingSoon = () => {
    const {t} = useTranslation()

    return (
        <section className="home_coming-soon">
            <h1><span>{t("upcomingModesTitlePartOne")}</span> <br/>{t("upcomingModesTitlePartTwo")}</h1>
        </section>
    )
}
export default HomeComingSoon