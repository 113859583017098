import React, {memo} from 'react';
import {SkeletonText} from "components/atoms";
import {useTranslation} from "react-i18next";

const Description = ({isLoading, isRewardClaimed, rewardSuccess}) => {
    const {t} = useTranslation()

    return (
        <div className="free-item-section_content_description">
            {!isRewardClaimed && <span className="free-item-section_content_description_title">{t("description")}</span>}
            {isLoading
                ? <SkeletonText textLineCount={3}/>
                : rewardSuccess
                    ? isRewardClaimed ? "" :
                        <p>{t("freeItemPageClaimText")}</p>
                    : <p>{t("freeItemPageClosedDescription")}</p>}
        </div>
    )
}

export default memo(Description)