import classNames from "classnames";
import React from "react"

import "./style.scss"
import {useTranslation} from "react-i18next";

const url = {
    ethereum: "https://dexscreener.com/ethereum/0x07840b4825b74cc6ce264bf2743dee647194f49b",
    base: "https://dexscreener.com/base/0xd9caa6dbe6791fcb7fc9fb59d1a6b3dd8c1c2339",
}

const TokenERC20 = () => {
    const {t} = useTranslation()

    return (
        <section className="ERC20">
            <h2>{t("tokenTabChainsTitle")}</h2>
            <div className="ERC20_content">
                <ERC20Card link={url.ethereum} type="ethereum" text="0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9"/>
                <ERC20Card link={url.base} type="base" text="0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C"/>
            </div>
        </section>
    )
}

export default TokenERC20

const ERC20Card = ({link, type, text}) => {
    const cardStyle = classNames("ERC20-card_img", type)

    return (
        <div className="ERC20-card" onClick={() => window.open(link, "_blank")}>
            <div className="ERC20-card_content">
                <h2>{type}</h2>
                <span>{text}</span>
            </div>
            <div className={cardStyle}></div>
        </div>
    )
}