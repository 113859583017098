import {Link, useNavigate} from "react-router-dom"
import React from "react"

import {useTranslation} from "react-i18next";

import Image from "../Image";
import "./style.scss"

const ArticleCart = ({text, img, date, slug}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const navigateTo = () => {
        window.dataLayer.push({post_id: slug, post_title: text})
        navigate(`/news/${slug}`)
    }

    return (
        <div className="article-cart">
            <div className="article-cart_img">
                <div onClick={() => navigateTo()}>
                    <Image alt="content-image" src={img}/>
                </div>
            </div>
            <div className="article-cart_info">
                <div className="article-cart_info_date">{date}</div>
                <h3>{text}</h3>
                <Link aria-label="news" className="article-cart_info_readmore" to={`/news/${slug}`}>{t("latestSectionArticlesButtonLabel")}</Link>
            </div>
        </div>
    )
}

export default ArticleCart