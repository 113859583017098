import React from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

import Title from "components/atoms/TitleTemplate";
import ProgressiveImage from "components/atoms/ProgressiveImage";

import {png} from "assets/png";
import "./style.scss"

const EconomyCollecting = () => {
    const {t} = useTranslation()

    return (
        <section className="economy_collecting">
            <Title underline={true}>{t("gameEconomyCosmeticsTitlePartOne")} <span>{t("gameEconomyCosmeticsTitlePartTwo")}</span></Title>
            <ItemBlock
                background="gray"
                className="item-three"
                orgImage={png.CosmeticHippoPng}
                noOrgImage={png.CosmeticHippoWebp}
                title={t("gameEconomyCosmeticsArticle3Title")}
            >
                {t("gameEconomyCosmeticsArticle3Description")}
            </ItemBlock>
            <ItemBlock
                reverse={true}
                background="red"
                className="item-four"
                orgImage={png.CosmeticEmojiPng}
                noOrgImage={png.CosmeticEmojiWebp}
                title={t("gameEconomyCosmeticsArticle4Title")}
            >
                {t("gameEconomyCosmeticsArticle4Description")}
            </ItemBlock>
            <ItemBlock
                background="gray"
                className="item-one"
                orgImage={png.CosmeticCarPng}
                noOrgImage={png.CosmeticCarWebp}
                title={t("gameEconomyCosmeticsArticle1Title")}
            >
                {t("gameEconomyCosmeticsArticle1Description")}
            </ItemBlock>
            <ItemBlock
                reverse={true}
                background="red"
                className="item-two"
                orgImage={png.CosmeticSniperPng}
                noOrgImage={png.CosmeticSniperWebp}
                title={t("gameEconomyCosmeticsArticle2Title")}
            >
                {t("gameEconomyCosmeticsArticle2Description")}
            </ItemBlock>
            <ItemBlock
                background="gray"
                className="item-five"
                orgImage={png.CosmeticSaharPng}
                noOrgImage={png.CosmeticSaharWebp}
                title={t("gameEconomyCosmeticsArticle5Title")}
            >
                {t("gameEconomyCosmeticsArticle5Description")}
            </ItemBlock>
        </section>
    )
}

export default EconomyCollecting

const ItemBlock = ({title, className, reverse, background, children, orgImage, noOrgImage}) => {
    const howItemStyle = classNames("economy_collecting_block_item", "container", className, {reverse: reverse})
    const homeItemParentStyle = classNames("economy_collecting_block", background ?? "")
    const imgBlockStyle = classNames("economy_collecting_block_item_img", background ?? "")

    return (
        <div className={homeItemParentStyle}>
            <div className={howItemStyle}>
                <div className={imgBlockStyle}>
                    <ProgressiveImage alt="hero" imgSrc={orgImage} previewSrc={noOrgImage}/>
                </div>
                <div>
                    <h3>{title}</h3>
                    <p>{children}</p>
                </div>
            </div>
        </div>
    )
}