import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom"
import React, {useEffect} from "react";

import {getPosts} from "store/NewsPosts/postSlice";
import {useTranslation} from "react-i18next";

import ArticleCart from "components/atoms/ArticleCart";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";

const HomeNews = () => {
    const {posts, success} = useSelector(state => state.posts)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(getPosts({first: 9, skip: 0})), [])

    return (
        <section className="home_news">
            <Title underline={true}>{t("latestSectionTitlePartOne")} <span>{t("latestSectionTitlePartTwo")}</span></Title>
            <div className="home_news_content container">
                {success &&
                    React.Children.toArray(
                        posts?.map((post) =>
                            <ArticleCart
                                date={post.date}
                                text={post.title}
                                img={post.image.url}
                                slug={post.slug}
                            />
                        )
                    )}
            </div>
            <div className="home_news_btn">
                <Button view="outline" onClick={() => navigate("/news")}>{t("latestSectionViewMoreButton")}</Button>
            </div>
        </section>
    )
}

export default HomeNews