import React, {useEffect, useState} from "react";
import DefaultLayout from "layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {getEthPrice} from "store/Global/globalSlice";

import ShopGlobal from "components/organisms/Shop/ShopTabs";
import Seo from "components/atoms/Seo";

const Shop = () => {
    const [widgetIsLoad, setWidgetIsLoad] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        let isScript = document.getElementById("xsolla-widget");
        if (!isScript) {
            let s = document.createElement('script');
            s.type = "text/javascript";
            s.id = "xsolla-widget"
            s.async = true;
            s.src = "//cdn.xsolla.net/embed/paystation/1.2.7/widget.min.js";
            s.addEventListener('load', function (e) {
                setWidgetIsLoad(true)
            }, false);
            let head = document.getElementsByTagName('head')[0];
            head.appendChild(s);
        }

        dispatch(getEthPrice())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { t } = useTranslation();
    return (
        <DefaultLayout>
            <Seo
                title="Shop - KOMPETE"
                description="The KOMPETE shop. Buy KOMPETE cosmetics online at our online shop."
            />
            <ShopGlobal widgetIsLoad={widgetIsLoad}/>
        </DefaultLayout>
    )
}
export default Shop