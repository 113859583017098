import React, {useState, memo, useEffect} from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {getDataFromCookies} from "utils";

import {SelectDropDownDisplay,Icon} from "components/atoms";
import ProfileInfoHeader from "./ProfileInfoHeader";

export const SUPPORTED_LANGUAGES = [
    {code: "en", name: "English"},
    {code: "de", name: "German"},
    {code: "fr", name: "French"},
    {code: "hi", name: "Hindi"},
    {code: "id", name: "Indonesian"},
    {code: "it", name: "Italian"},
    {code: "ja", name: "Japanese"},
    {code: "ko", name: "Korean"},
    {code: "ru", name: "Russian"},
    {code: "nl", name: "Dutch"},
    {code: "pl", name: "Polish"},
    {code: "pt", name: "Portuguese"},
    {code: "sv", name: "Swedish"},
    {code: "th", name: "Thai"},
    {code: "tr", name: "Turkish"},
    {code: "vi", name: "Vietnamese"},
    {code: "zh-CN", name: "Chinese (Simplified)"},
    {code: "zh-TW", name: "Chinese (Traditional)" },
    {code: "es", name: "Spanish"},
];

const ProfileLanguageSelectBlock = () => {
    const [value, setValue] = useState({})
    const {t} = useTranslation()

    useEffect(() => {
        checkLanguage()
    }, []);

    const options = SUPPORTED_LANGUAGES.map((language) => ({
        value: language.code,
        name: (
            <div className="select_container_language-option">
                <Icon name={language.code}/>
                <span>{language.name}</span>
            </div>
        ),
    }));

    const handleChange = (selectedOption) => {
        setValue(selectedOption)
        i18next.changeLanguage(selectedOption.value)
        localStorage.setItem("i18next", selectedOption.value)
    };

    const checkLanguage = () => {
        const language = getDataFromCookies("i18next")

        if (language) {
            setValue(options.find(option => option.value === language))
        } else {
            setValue(options.find(option => option.value === "en"))
        }
    }

    return (
        <div className="profile_container_info_langauge">
            <ProfileInfoHeader title={t("language")}/>
            <SelectDropDownDisplay
                size="lg"
                value={value}
                status="default"
                btnText="Select..."
                optionsList={options}
                onChange={(e) => handleChange(e.target.value.value)}
            />
        </div>
    );
};

export default memo(ProfileLanguageSelectBlock);