import React from "react"
import {useTranslation} from "react-i18next";

import PageMain from "components/atoms/PageMain";
import {png} from "assets/png";

const NewsMain = () => {
    const {t} = useTranslation()

    return (
        <PageMain
            page="news"
            filter={false}
            originalImage={png.HeroNews}
            title={t("newsPageTitle")}
            resizeImage={png.HeroNewsResized}
            text={<>{t("newsPageIntroductionText")}</>}
        />
    )
}

export default NewsMain