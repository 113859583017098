import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {setBackgroundClass} from "store/Global/globalSlice"

import DefaultLayout from "layouts/DefaultLayout";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"


const PageNotFound = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(setBackgroundClass("fire-back"))

        return (() => {
            dispatch(setBackgroundClass(""))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <DefaultLayout isFollowUsShow={false}>
            <div className="page-not-found-container">
                <div className="page-not-found-container_block">
                    <div className="page-not-found-container_block_img">
                        <Image alt="404" src={png.NotFound}/>
                    </div>
                    <h1 className="page-not-found-container_block_title">{t("errorPageSubtitle")}</h1>
                    <p className="page-not-found-container_block_text">{t("errorPageDescription")}</p>
                    <Button
                        view="secondary"
                        onClick={() => navigate("/")}
                    >
                        {t("errorPageButtonLabel")}
                    </Button>
                </div>
            </div>
        </DefaultLayout>
    )
}
export default PageNotFound