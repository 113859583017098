import React from "react";
import {useTranslation} from "react-i18next";

import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const TokenThree = () => {
    const {t} = useTranslation()

    return (
        <section className="token-three">
            <div className="container">
                <Title underline={true}>{t("tokenTabTokenomicsTitle")}</Title>
                <div className="tokenomics">
                    <div className="item">
                        <div className="thumb">
                            <Image alt="kompete-token" src={png.T12}/>
                        </div>
                        <h3>{t("tokenTabTokenomicsArticle1Title")}</h3>
                        <p>{t("tokenTabTokenomicsArticle1Content")}</p>
                    </div>
                    <div className="item">
                        <div className="thumb">
                            <Image alt="komoete-token" src={png.T13}/>
                        </div>
                        <h3>{t("tokenTabTokenomicsArticle2Title")}</h3>
                        <p>{t("tokenTabTokenomicsArticle2Content")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenThree