import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {useAuthorization, useConnectedWallet, useConnectWallet, useLocalStorage, useUtils} from "hooks";
import {ERROR, formatNumberToEuropeanStyle, GLOBAL_MODALS, STEPS_STATUS, SUCCESS} from "utils";
import {useModalsContext, useGlobalModalsContext} from "layouts";

import LimitedTimeCurrentCosmeticEthPrice from "./LimitedTimeCurrentCosmeticEthPrice";
import LimitedTimeCurrentCosmeticSteps from "./LimitedTimeCurrentCosmeticSteps";
import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import {Button, Image, QuantityCalculator} from "components/atoms";
import {CountDownWithContainer} from "components/molecules";

import {png} from "assets/png";
import {svg} from "assets/svg";
import "./style.scss"

const LimitedTimeCurrentCosmeticMain = ({shopData}) => {
    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {setWalletConnectionSuccess} = useLocalStorage()
    const {disconnectWallet} = useConnectWallet()
    const {setCurrentModal} = useModalsContext()
    const {isAuth} = useAuthorization()
    const {addChain} = useUtils()
    const {t} = useTranslation()

    const {ethPrice} = useSelector(state => state.global)
    const [quantityValue, setQuantityValue] = useState(1)

    const {inventory, expiration_date} = shopData

    const currentCosmeticStyle = classNames("limited-time-current-cosmetic_main", inventory.rarity)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkWalletConnectionAndAddressMatch(), [isConnected]);

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            setModal(GLOBAL_MODALS.PROCESS)
            const {status} = await addChain()

            if (!status) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.FAIL_REQUEST)

                resetStatesAndClose()
                disconnectWallet()
            } else {
                if (!isConnectedAddressCorrect) {
                    setCurrentModal({status: STEPS_STATUS.CLOSE, message: ""})
                    setModal(GLOBAL_MODALS.ERROR)
                    setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)

                    resetStatesAndClose()
                    disconnectWallet()
                } else {
                    setModal(GLOBAL_MODALS.SUCCESS)
                    setMessage(SUCCESS.WALLET_CONNECTED)
                    resetStatesAndClose()
                    setWalletConnectionSuccess()
                }
            }
        }
    }

    const openOrderModal = () => {
        const oneVoolahPrice = 0.01 //USD
        const cosmeticEthPrice =
            (((quantityValue * (inventory.voolah_price * oneVoolahPrice)) / ethPrice) * 1000000) / 1000000

        setCurrentModal({status: STEPS_STATUS.ORDER, data: {...inventory, ethPrice: cosmeticEthPrice}})
    }

    const reloadPage = () => {
        window.location.reload()
    }

    return (
        <section className={currentCosmeticStyle}>
            <CosmeticViewTemplate
                loading={false}
                title={inventory.name}
                rarity={inventory.rarity}
                img={inventory.image_url}
                attribute={[{name: inventory.sub_category}]}
                isNeverSoldAgain={inventory.never_sold_again}
                classname="limited-time-current-cosmetic_main_content container"
            >
                <div className="limited-time-current-cosmetic_main_content_deadline">
                    <CountDownWithContainer
                        day={true}
                        success={true}
                        loading={false}
                        isReloadPage={true}
                        date={expiration_date}
                        callBackReload={reloadPage}
                        text={`${t("offerExpires")}`}
                    />
                </div>
                <div className="limited-time-current-cosmetic_main_content_price-info">
                    <div className="limited-time-current-cosmetic_main_content_price-info_price">
                        <span className="block-title">
                            {t("priceFor")} {quantityValue}
                        </span>
                        <div className="limited-time-current-cosmetic_main_content_price-info_price_content">
                            <div className="limited-time-current-cosmetic_main_content_price-info_price_content_voolah">
                                <Image src={png.VoolahCoin} alt="voolah"/>
                                <span>{formatNumberToEuropeanStyle(quantityValue * Number(inventory?.voolah_price))}</span>
                            </div>
                            <div className="limited-time-current-cosmetic_main_content_price-info_price_content_text">
                                {t("or")}
                            </div>
                            <div className="limited-time-current-cosmetic_main_content_price-info_price_content_eth">
                                <Image src={svg.EtheriumGold} alt="Ethereum"/>
                                <LimitedTimeCurrentCosmeticEthPrice
                                    quantity={quantityValue}
                                    voolahPrice={inventory.voolah_price}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="limited-time-current-cosmetic_main_content_price-info_quantity">
                        <span className="block-title">
                            {t("quantity")}
                        </span>
                        <QuantityCalculator value={quantityValue} setValue={setQuantityValue} limit={5}/>
                    </div>
                </div>
                <Button
                    onClick={() =>
                        isAuth
                            ? openOrderModal()
                            : setModal(GLOBAL_MODALS.LOGIN_ALERT)
                    }
                    disabled={!ethPrice}
                >
                    {t("buyButtonText")}
                </Button>
                <div className="limited-time-current-cosmetic_main_content_description">
                    <span className="block-title">{t("description")}</span>
                    <p>{inventory?.description}</p>
                </div>
            </CosmeticViewTemplate>
            <LimitedTimeCurrentCosmeticSteps quantity={quantityValue}/>
        </section>
    )
}

export default LimitedTimeCurrentCosmeticMain