import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const FooterModernize = () => {
    const {t} = useTranslation()

    return (
        <div className="footer-modernize">
            <div className="footer-modernize_block">
                <div className="footer-modernize_block_logo">
                    <Image
                        onClick={() => window.open("https://modernizegames.com", "_blank")}
                        src={png.LogoModernizeGame}
                        alt="logo modernize"
                    />
                </div>
                <div className="footer-modernize_block_reserved">
                    <p>{t("footerSectionDeveloperNote")}</p>
                </div>
                <div className="footer-modernize_block_term-links">
                    <ul>
                        <li><Link to="/privacy">{t("privacyTermsPrivacy")}</Link></li>
                        <li><Link to="/terms">{t("privacyTermsTerms")}</Link></li>
                        <li><a href="#" target="_blank" rel="noreferrer">{t("privacyTermsCookies")}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default FooterModernize