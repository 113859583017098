import React from "react"
import {useTranslation} from "react-i18next";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {ButtonV2, Icon} from "components/atoms";
import {GLOBAL_MODALS} from "utils";

import "./style.scss"

const SignIn = () => {
    const {setModal} = useGlobalModalsContext()
    const {t} = useTranslation()

    return (
        <div className="protected-container_sign-in">
            <div className="protected-container_sign-in_img">
                <Icon name="protected"/>
            </div>
            <p className="protected-container_sign-in_text">{t("protectedModalText")}</p>
            <ButtonV2 onClick={() => setModal(GLOBAL_MODALS.SIGN_IN)}>{t("signInModalFormSignInButton")}</ButtonV2>
        </div>
    )
}

export default SignIn